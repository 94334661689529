import React from "react"
import ChangePassword from "./ChangePassword"
import AdminHeader from "./Header"

const AdminProfile = () => {
    return <div className="fixed-top-bar">
        <AdminHeader />
        <ChangePassword />
    </div>
}

export default AdminProfile