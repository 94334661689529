import React from "react"
import { ADD } from "../../include/constant"
import VoucherForm from "../common/VoucherForm"
import SuperAdminHeader from "./Header"

const SuperAdminAddVoucher = () => {
    return <div className="fixed-top-bar">
        <SuperAdminHeader />
        <VoucherForm
            title="Add Voucher"
            type={ADD}
        />
    </div>
}

export default SuperAdminAddVoucher