import React from "react"
import { useParams } from "react-router-dom"
import { EDIT } from "../../include/constant"
import CategoryForm from "../common/CategoryForm"
import SuperAdminHeader from "./Header"

const SuperAdminEditCategory = () => {
    const { id } = useParams()

    return <div className="fixed-top-bar">
        <SuperAdminHeader />
        <CategoryForm
            id={id}
            title="Edit Category"
            type={EDIT}
        />
    </div>
}

export default SuperAdminEditCategory