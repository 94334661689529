import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { discountPrice, discountValue, getCart, getTotal, preventArrow, preventClick, preventWheel, setCart, setToLocalStorage } from "../../include/function"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"
import { nanoid } from "nanoid"
import { CHECKOUT } from "../../include/constant"

const CustomerCart = () => {
    const history = useHistory()

    const [localCart, setLocalCart] = useState(getCart())
    const [cartProducts, setCartProducts] = useState([])
    const [orderItems, setOrderItems] = useState([])
    const [fetchLoading, setFetchLoading] = useState(true)
    const [checkout, setCheckout] = useState('')
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalDeliveryCharge, setTotalDeliveryCharge] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false)

    useEffect(() => {
        let idArray = localCart.map(cart => cart.id)
        if (idArray.length > 0) {
            idArray = JSON.stringify(idArray)
            getCartProducts(idArray)
        }
    }, [localCart])

    useEffect(() => {
        if (cartProducts.length > 0 && localCart.length > 0) {
            const products = cartProducts.slice()
            for (let index = 0; index < localCart.length; index++) {
                const cartItem = localCart[index]
                const productIndex = products.findIndex(cp => String(cp.id) === String(cartItem.id))
                if (productIndex >= 0) {
                    const qty = Number(cartItem.qty)
                    const price = Number(products[productIndex].price)
                    const discountValue = Number(products[productIndex].discountValue)
                    const discountMode = products[productIndex].discountMode
                    const amount = (discountValue === '' || Number(discountValue) === 0) ? qty * price : qty * discountPrice(price, discountValue, discountMode)
                    const product = {
                        ...products[productIndex],
                        qty: qty,
                        amount: amount,
                    }
                    products[productIndex] = product
                    setOrderItems(products)
                }
            }
        }
    }, [localCart, cartProducts])

    useEffect(() => {
        const activeOrderItems = orderItems.filter(oi => oi.status === 'active' && oi.shopStatus === 'active')
        const checkout = {
            billAmount: totalPrice,
            totalDiscount: totalDiscount,
            totalDeliveryCharge: totalDeliveryCharge,
            totalAmount: totalAmount,
            totalItems: totalItems,
            orderItems: activeOrderItems
        }
        setCheckout(checkout)
    }, [localCart, orderItems, totalPrice, totalAmount, totalDiscount, totalDeliveryCharge, totalItems])

    useEffect(() => {
        const activeOrderItems = orderItems.filter(oi => oi.status === 'active' && oi.shopStatus === 'active')
        const totalPrice = getTotal(activeOrderItems, item => (item.price * item.qty))
        const totalDiscount = getTotal(activeOrderItems, item => discountValue((item.price * item.qty), item.discountValue, item.discountMode))
        const totalDeliveryCharge = getTotal(activeOrderItems, item => Number(item.deliveryCharge) * Number(item.qty))
        const totalAmount = (totalPrice + totalDeliveryCharge) - totalDiscount

        setTotalPrice(totalPrice)
        setTotalDiscount(totalDiscount)
        setTotalDeliveryCharge(totalDeliveryCharge)
        setTotalAmount(totalAmount)
        setTotalItems(activeOrderItems.length)
    }, [orderItems])

    const getCartProducts = (idArray) => {
        setFetchLoading(true)
        api.get('/product/?idArray=' + idArray, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setCartProducts(response.data.data)
            }
        }).catch(error => {
            setCartProducts([])
        }).finally(() => setFetchLoading(false))
    }

    const handleCheckout = async () => {
        setPlaceOrderLoading(true)
        await setToLocalStorage(CHECKOUT, checkout)
        setTimeout(() => {
            history.push('/checkout')
        }, 1000)
    }

    return <div className="fixed-top-bar">
        <CustomerHeader />
        <div className="container">
            <h5 className="m-3">Cart</h5>
            <div className="overflow-hidden p-3">
                <div className="row">
                    <div className="col-12 col-lg-6 my-2">
                        <div className="border border-1 shadow-sm">
                            {localCart && localCart.length > 0 ? localCart.map(item => {
                                let product = {}
                                if (orderItems.length > 0) {
                                    let index = orderItems.findIndex(cp => String(cp.id) === String(item.id))
                                    if (index >= 0) product = orderItems[index]
                                }
                                return <CartProducts
                                    fetchLoading={fetchLoading}
                                    item={item}
                                    localCart={localCart}
                                    product={product}
                                    setLocalCart={setLocalCart}
                                    key={nanoid()}
                                />
                            }) : <Error mainText="Your Cart is empty" secondaryText={<Link to="/">Continue shopping</Link>} />}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 my-2">
                        {localCart && localCart.length > 0 && <div className="border border-1 position-sticky shadow-sm">
                            <div className=" px-3 py-2 border-bottom">
                                <h6 className="text-muted m-0">PRICE DETAILS</h6>
                            </div>
                            <div className="p-3">
                                <h6 className="d-flex justify-content-between">
                                    <span className="text-muted">Price ({totalItems} Items)</span>
                                    <span>&#8377;{totalPrice}</span>
                                </h6>
                                <h6 className="d-flex justify-content-between text-success">
                                    <span>Discount</span>
                                    <span>&#8377;{totalDiscount}</span>
                                </h6>
                                <h6 className="d-flex justify-content-between">
                                    <span className="text-muted">Delivery Charge</span>
                                    {(totalDeliveryCharge === '' || totalDeliveryCharge === 0 || totalDeliveryCharge === '0') ?
                                        <span className="text-success text-uppercase">Free</span> :
                                        <span>&#8377;{totalDeliveryCharge}</span>}
                                </h6>
                                <hr />
                                <h6 className="d-flex justify-content-between  fw-bold">
                                    <span>Total Amount</span>
                                    <span>&#8377;{totalAmount}</span>
                                </h6>
                                <hr />
                                <span className="text-success small">You will save ₹{totalDiscount} on this order</span>
                                <div className="d-flex justify-content-end">
                                    {placeOrderLoading ? <button className="btn btn-lg" onClick={e => e.preventDefault()}>Place order</button> :
                                        <button className="btn btn-primary btn-lg" onClick={e => preventClick(e, handleCheckout)}>Place order</button>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const CartProducts = ({ item, product, localCart, setLocalCart, fetchLoading }) => {
    const removeItem = (id) => {
        let itemRemovedCart = localCart.filter(c => String(c.id) !== String(id))
        setLocalCart(itemRemovedCart)
        setCart(itemRemovedCart)
    }

    const handleIncreaseCount = (item) => {
        let cart = localCart
        let index = cart.findIndex(c => String(item.id) === String(c.id))
        if (index >= 0) {
            cart = cart.slice()
            let existingItem = cart[index]
            let updatedUnitsItem = {
                ...existingItem,
                qty: Number(existingItem.qty) + 1
            }
            cart[index] = updatedUnitsItem
            setLocalCart(cart)
            setCart(cart)
        }
    }

    const handleDecreaseCount = (item) => {
        let cart = localCart
        let index = cart.findIndex(c => String(item.id) === String(c.id))
        if (index >= 0) {
            cart = cart.slice()
            let existingItem = cart[index]
            let updatedUnitsItem = {
                ...existingItem,
                qty: Number(existingItem.qty) === 1 ? Number(existingItem.qty) : Number(existingItem.qty) - 1
            }
            cart[index] = updatedUnitsItem
            setLocalCart(cart)
            setCart(cart)
        }
    }

    return fetchLoading ? <div className="p-5"><Spinner /></div> : <div className="container border-bottom py-3">
        <div className="row">
            <div className="col-12 col-sm-3">
                <img className="w-100 fit-contain" src={baseURL + '/' + product.mainImage} alt="" style={{ minHeight: '50px', maxHeight: '150px' }} />
            </div>
            <div className="col-12 col-sm-9">
                {(product.status === 'active' && product.shopStatus === 'active') ? <>
                    <h6 className="my-2 text-truncate">{product.name}</h6>
                    {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0 ?
                        <h6 className="fw-bold">&#8377;{Math.ceil(product.price)}</h6> :
                        <h6 className="fw-bold">
                            <span>&#8377;{Math.ceil(discountPrice((product.price * product.qty), product.discountValue, product.discountMode))}</span>
                            <del className="text-muted mx-2">&#8377;{Math.ceil(product.price * product.qty)}</del>
                            <span className="text-success">{product.discountLabel}</span>
                        </h6>}
                    <div className="d-flex align-items-center mt-3 w-50">
                        {Number(item.qty) === 1 ?
                            <i className=" fas fa-minus-circle  shadow-sm rounded-circle me-2" /> :
                            <i onClick={e => handleDecreaseCount(item)} className="text-primary fas fa-minus-circle  shadow-sm cursor-pointer rounded-circle me-2" />}
                        <input type="number" className="count-box p-0 text-center" onKeyDown={e => preventArrow(e)} onFocus={e => preventWheel(e)} value={item.qty} onChange={e => e.preventDefault()} />
                        <i onClick={e => handleIncreaseCount(item)} className="fas fa-plus-circle  shadow-sm text-primary cursor-pointer rounded-circle ms-2" />
                        <button className="btn btn-danger px-3 ms-4" onClick={e => removeItem(product.id)}><i className="far fa-trash-alt  font-weight-normal" /></button>
                    </div>
                </> : <div>
                    <div className="badge bg-danger text-uppercase">Unavailable</div>
                    <button className="btn btn-danger px-3 ms-4" onClick={e => removeItem(product.id)}><i className="far fa-trash-alt  font-weight-normal" /></button>
                </div>}
            </div>
        </div>
    </div>
}

export default CustomerCart