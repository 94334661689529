import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { api, superAdminReqConfig } from "../../include/api"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import SuperAdminHeader from "./Header"

const SuperAdminCustomer = () => {

    const [customers, setCustomers] = useState([])
    const [fetchingCustomers, setFetchingCustomers] = useState(true)

    useEffect(() => getCustomers(), [])

    const getCustomers = () => {
        setFetchingCustomers(true)
        api.get('/customer/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setCustomers(response.data.data)
            }
        }).catch(error => {
            setCustomers([])
        }).finally(() => setFetchingCustomers(false))
    }

    return <div className="fixed-top-bar">
        <SuperAdminHeader />
        <div className="container my-3">
            <div className="py-3">
                <h5 className="m-0">Customer</h5>
            </div>
            {fetchingCustomers ?
                <Spinner color="danger" spinnerSize="lg" /> :
                customers.length > 0 ?
                    <div className="row">
                        {customers.map((customer, index) => {
                            return <Customer customer={customer} key={nanoid()} />
                        })}
                    </div> : <Error mainText="No customer found!" />}
        </div>
    </div>
}

const Customer = ({ customer }) => {
    return <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
        <div className="border shadow-sm p-2">
            <h6 className="d-flex align-items-center justify-content-between fw-bold">
                <div className="text-truncate">
                    <i className={`fas fa-circle blink ${customer.status === 'active' ? 'text-success' : 'text-danger'}`} style={{ fontSize: '.7rem' }} />
                    <span className="ps-2">{customer.name}</span>
                </div>
            </h6>
            <hr className="my-2" />
            <p className="small mb-1 text-muted">
                <i className="fas fa-mobile-alt fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{customer.phoneNumber}</span>
            </p>
            <p className="small mb-1 text-muted">
                <i className="fas fa-envelope fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{customer.emailId}</span>
            </p>
        </div>
    </div>
}

export default SuperAdminCustomer