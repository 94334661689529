import React from "react"
import Header from "../common/Header"

const SuperAdminHeader = () => {
    const homeLink = '/super'
    const menuContent = [
        {
            url: "/super/shop",
            text: "Shop",
            icon: "store"
        },
        {
            url: "/super/customer",
            text: "Customer",
            icon: "user"
        },
        {
            url: "/super/category",
            text: "Category",
            icon: "list"
        },
        {
            url: "/super/voucher",
            text: "Voucher",
            icon: "ticket-alt"
        },
        {
            url: "/super/slide",
            text: "Slide",
            icon: "images"
        },
        {
            url: "/super/home_page",
            text: "Home Page",
            icon: "home"
        },
        {
            url: "/super/logout",
            text: "Logout",
            icon: "sign-out-alt"
        }
    ]

    return <Header
        homeLink={homeLink}
        menuContent={menuContent}
    />
}

export default SuperAdminHeader
