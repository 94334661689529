import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, superAdminReqConfig } from "../../include/api"
import { isEmptyObj, preventArrow, preventClick, preventWheel, req, tooLong, validateEmail, validatePhoneNumber, validatePincode } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import { ADD, EDIT, ERROR, SUCCESS } from "../../include/constant"
import Spinner from "../add-ons/Spinner"

const ShopForm = ({ type, title, id }) => {
    const toast = useNotification()

    const [saving, setSaving] = useState(false)
    const [fetchingShop, setFetchingShop] = useState(type === EDIT)

    const [shop, setShop] = useState({})
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [emailId, setEmailId] = useState('')
    const [area, setArea] = useState('')
    const [address, setAddress] = useState('')
    const [pin, setPin] = useState('')
    const [status, setStatus] = useState('')

    const [userIdError, setUserIdError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [nameError, setNameError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [emailIdError, setEmailIdError] = useState('')
    const [areaError, setAreaError] = useState('')
    const [addressError, setAddressError] = useState('')
    const [pinError, setPinError] = useState('')
    const [statusError, setStatusError] = useState('')

    const getShop = useCallback((afterGet) => {
        setFetchingShop(true)
        api.get('/shop/?id=' + id, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setShop(response.data.data[0])
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setShop({})
            if (afterGet) afterGet([])
        }).finally(() => setFetchingShop(false))
    }, [id])

    const handleUserId = (value) => {
        setUserIdError('')
        setUserId(String(value).trim().toLowerCase())
    }

    const handlePassword = (value) => {
        setPasswordError('')
        setPassword(value)
    }

    const handleName = (value) => {
        setNameError('')
        setName(value)
    }

    const handlePhoneNumber = (value) => {
        setPhoneNumberError('')
        setPhoneNumber(value)
    }

    const handleEmailId = (value) => {
        setEmailIdError('')
        setEmailId(value)
    }

    const handleArea = (value) => {
        setAreaError('')
        setArea(value)
    }

    const handleAddress = (value) => {
        setAddressError('')
        setAddress(value)
    }

    const handlePin = (value) => {
        setPinError('')
        setPin(value)
    }

    const handleStatus = (value) => {
        setStatusError('')
        setStatus(value)
    }

    const clearFields = () => {
        setUserId('')
        setPassword('')
        setName('')
        setPhoneNumber('')
        setEmailId('')
        setArea('')
        setAddress('')
        setPin('')
        setStatus('')

        setUserIdError('')
        setPasswordError('')
        setNameError('')
        setPhoneNumberError('')
        setEmailIdError('')
        setAreaError('')
        setAddressError('')
        setPinError('')
        setStatusError('')
    }

    const handleSave = () => {
        let error = false

        const data = {
            id: String(id).trim(),
            userId: String(userId).trim().toLowerCase(),
            password: String(password).trim(),
            name: String(name).trim(),
            phoneNumber: String(phoneNumber).trim(),
            emailId: String(emailId).trim().toLowerCase(),
            area: String(area).trim(),
            address: String(address).trim(),
            pin: String(pin).trim(),
            status: String(status).trim(),
        }

        if (type === ADD && data.userId === '') {
            setUserIdError(req('Username'))
            error = true
        } else if (type === ADD && data.userId.length > 100) {
            setUserIdError(tooLong('Username'))
            error = true
        }

        if (type === ADD && data.password === '') {
            setPasswordError('Password required')
            error = true
        } else if (type === ADD && data.password.length < 6) {
            setPasswordError('Password must be at least 6 characters')
            error = true
        }

        if (data.name === '') {
            setNameError(req('Name'))
            error = true
        } else if (data.name.length > 500) {
            setNameError(tooLong('Name'))
            error = true
        }

        if (data.phoneNumber === '') {
            setPhoneNumberError('Phone number required')
            error = true
        } else if (!validatePhoneNumber(data.phoneNumber)) {
            setPhoneNumberError('Invalid phone number')
            error = true
        }

        if (data.emailId !== '' && !validateEmail(data.emailId)) {
            setEmailIdError('Invalid email address')
            error = true
        }

        if (data.area === '') {
            setAreaError(req('Area'))
            error = true
        } else if (data.area.length > 100) {
            setAreaError(tooLong('Area'))
            error = true
        }

        if (data.address === '') {
            setAddressError(req('Address'))
            error = true
        } else if (data.address.length > 500) {
            setAddressError(tooLong('Address'))
            error = true
        }

        if (data.pin === '') {
            setPinError(req('Pincode'))
            error = true
        } else if (!validatePincode(data.pin)) {
            setPinError('Invalid pincode')
            error = true
        }

        if (data.status === '') {
            setStatusError(req('Status'))
            error = true
        }

        if (!error) {
            if (type === ADD) {
                setSaving(true)
                api.post('/shop/', data, superAdminReqConfig()).then(response => {
                    if (response.status === 200) {
                        clearFields()
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            } else if (type === EDIT) {
                setSaving(true)
                api.put('/shop/?id=' + data.id, data, superAdminReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            }
        }
    }

    useEffect(() => {
        if (type === EDIT) getShop()
    }, [getShop, type])

    useEffect(() => {
        if (!isEmptyObj(shop)) {
            setName(shop.name || '')
            setPhoneNumber(shop.phoneNumber || '')
            setEmailId(shop.emailId || '')
            setArea(shop.area || '')
            setAddress(shop.address || '')
            setPin(shop.pin || '')
            setStatus(shop.status || '')
        }
    }, [shop])

    return <div className="container my-3">
        <div className="py-3 d-flex justify-content-between align-items-center">
            <h5 className="m-0">{title}</h5>
            {saving ? <Link to="#" className="btn" onClick={e => e.preventDefault()}>Save</Link> :
                <Link to="#" className="btn btn-primary" onClick={e => preventClick(e, handleSave)}>Save</Link>}
        </div>
        {fetchingShop ? <Spinner color="danger" /> : <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <label htmlFor="name" className="small">Name</label>
                    <input type="text" className={`form-control form-control-lg ${nameError !== '' && 'border-danger'}`} placeholder="Name" id="name" autoFocus required autoComplete="off" onChange={e => handleName(e.target.value)} value={name} />
                    <span className="text-danger my-2 small fw-bold">{nameError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="userId" className="small">Username</label>
                    <input type="text" className={`form-control form-control-lg ${userIdError !== '' && 'border-danger'}`} placeholder="Username" id="userId" required autoComplete="off" onChange={e => handleUserId(e.target.value)} value={userId} disabled={type === EDIT} readOnly={type === EDIT} />
                    <span className="text-danger my-2 small fw-bold">{userIdError}</span>
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <label htmlFor="password" className="small">Password</label>
                    <input type="password" className={`form-control form-control-lg ${passwordError !== '' && 'border-danger'}`} placeholder="Password" id="password" required autoComplete="off" onChange={e => handlePassword(e.target.value)} value={password} disabled={type === EDIT} readOnly={type === EDIT} />
                    <span className="text-danger my-2 small fw-bold">{passwordError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="phoneNumber" className="small">Phone Number</label>
                    <input type="number" className={`form-control form-control-lg ${phoneNumberError !== '' && 'border-danger'}`} placeholder="Phone Number" id="phoneNumber" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handlePhoneNumber(e.target.value)} value={phoneNumber} />
                    <span className="text-danger my-2 small fw-bold">{phoneNumberError}</span>
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <label htmlFor="emailId" className="small">Email Address</label>
                    <input type="email" className={`form-control form-control-lg ${emailIdError !== '' && 'border-danger'}`} placeholder="Email Address" id="emailId" required autoComplete="off" onChange={e => handleEmailId(e.target.value)} value={emailId} />
                    <span className="text-danger my-2 small fw-bold">{emailIdError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="area" className="small">Area</label>
                    <input type="text" className={`form-control form-control-lg ${areaError !== '' && 'border-danger'}`} placeholder="Area" id="area" required autoComplete="off" onChange={e => handleArea(e.target.value)} value={area} />
                    <span className="text-danger my-2 small fw-bold">{areaError}</span>
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <label htmlFor="pin" className="small">Pincode</label>
                    <input type="number" className={`form-control form-control-lg ${pinError !== '' && 'border-danger'}`} placeholder="Pincode" id="pin" required autoComplete="off" onChange={e => handlePin(e.target.value)} value={pin} />
                    <span className="text-danger my-2 small fw-bold">{pinError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label className="small">Status</label>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="active" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${status === 'active' ? 'bg-success text-white fw-bold' : ''}`}>
                                <span>Active</span>
                                <input type="radio" className="form-check-input" name="status" id="active" checked={status === 'active'} onChange={e => handleStatus('active')} />
                            </label>
                        </div>
                        <div className="col-6">
                            <label htmlFor="inactive" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${status === 'inactive' ? 'bg-danger text-white fw-bold' : ''}`}>
                                <span>Inactive</span>
                                <input type="radio" className="form-check-input" name="status" id="inactive" checked={status === 'inactive'} onChange={e => handleStatus('inactive')} />
                            </label>
                        </div>
                    </div>
                    <span className="text-danger my-2 small fw-bold">{statusError}</span>
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="address" className="small">Address</label>
                    <textarea className={`form-control resize-none w-100 ${addressError !== '' && 'border-danger'}`} rows="5" id="address" placeholder="Address" autoComplete="off" onChange={e => handleAddress(e.target.value)} value={address} />
                    <span className="text-danger my-2 small fw-bold">{addressError}</span>
                    <br />
                </div>
            </div>
        </>}
    </div>
}

export default ShopForm