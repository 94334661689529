import React, { useState } from "react"
import { nanoid } from "nanoid"
import { Link, useLocation } from "react-router-dom"
import SideNavBar from "../common/SideNavBar"
import { getCustomer, isEmptyObj } from "../../include/function"

const CustomerHeader = ({ enableSearch = false, enableButtons = false }) => {
    const customer = getCustomer()

    const [toggleClass, setToggleClass] = useState(false)
    const menuContent = !isEmptyObj(customer) ? [
        {
            url: "/profile",
            text: "Profile",
            icon: "user"
        },
        {
            url: "/cart",
            text: "Cart",
            icon: "shopping-cart"
        },
        {
            url: "/logout",
            text: "Logout",
            icon: "sign-out-alt"
        }
    ] : [{ url: "/login", text: "Login", icon: "sign-in-alt" }]

    return <div>
        {enableButtons && <div className="d-block d-lg-none">
            <SideNavBar
                content={menuContent}
                setToggleClass={setToggleClass}
                toggleClass={toggleClass}
            />
        </div>}
        <header className="w-100 overflow-hidden bg-primary p-2 fixed-top">
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-8 col-lg-2 d-flex align-items-center h-100">
                        {enableButtons && <i className="d-block d-lg-none fas fa-bars h3 m-0 cursor-pointer text-white mx-1 me-3" onClick={() => setToggleClass('open')} />}
                        <h4 className="m-0 d-flex align-items-center justify-content-center h-100 text-truncate">
                            <Link to="/" className="text-white fw-bold text-uppercase">My Shop!</Link>
                        </h4>
                    </div>
                    {enableSearch && <div className="d-none d-lg-block col-lg-5 h-100">
                        <div className="d-flex align-items-center h-100">
                            <div className="input-group">
                                <input type="search" className="form-control" placeholder="Search" aria-label="Search" aria-describedby="search" />
                                <button className="input-group-text bg-danger" id="search">
                                    <i className="fas fa-search text-white" />
                                </button>
                            </div>
                        </div>
                    </div>}
                    {enableButtons && <div className="col-4 col-lg-5 h-100">
                        <div className="d-none d-lg-block h-100">
                            <div className="d-flex align-items-center h-100">
                                {menuContent.map(menu => <MenuContent
                                    menu={menu}
                                    key={nanoid()}
                                />)}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </header>
    </div>
}

const MenuContent = ({ menu }) => {
    const { pathname } = useLocation()
    return <Link to={menu.url} className={`${menu.url === pathname ? 'bg-danger rounded' : ''} text-white small fw-bold text-uppercase text-truncate py-2 px-3 d-inline-block`}>{menu.text}</Link>
}
export default CustomerHeader