import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './index.css'
import './styles/Toast.css'
import ToastProvider from './Toast/ToastProvider'
import NetworkStatusIndicator from './components/add-ons/NetworkStatusIndicator'

ReactDOM.render(
    <React.StrictMode>
        <ToastProvider>
            <App />
            <NetworkStatusIndicator />
        </ToastProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
