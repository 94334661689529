import React, { useEffect, useRef, useState } from "react"
import { nanoid } from "nanoid"
import { api, superAdminReqConfig } from "../../include/api"
import { isEmptyObj, preventArrow, preventClick, preventWheel, req, tooLong } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import { Link } from "react-router-dom"
import { ADD, EDIT, ERROR, SUCCESS } from "../../include/constant"

const CategoryForm = ({ type, title, id }) => {
    const toast = useNotification()

    const imageElement = useRef()

    const [saving, setSaving] = useState(false)
    const [savingImage, setSavingImage] = useState(false)
    const [fetchingCategory, setFetchingCategory] = useState(true)

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setimage] = useState('')
    const [imagePreview, setImagePreview] = useState('')
    const [parentId, setParentId] = useState('')
    const [nameError, setNameError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [imageError, setImageError] = useState('')
    const [parentIdError, setParentIdError] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState({})

    const getCategoryList = (afterGet) => {
        setFetchingCategory(true)
        api.get('/category/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setCategoryList(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setCategoryList([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchingCategory(false))
    }

    const handleName = (value) => {
        setNameError('')
        setName(value)
    }

    const handleDescription = (value) => {
        setDescriptionError('')
        setDescription(value)
    }

    const handleParentId = (value) => {
        setParentIdError('')
        setParentId(value)
    }

    const handleImage = (image) => {
        setImageError('')
        if (image.length > 0) {
            image = image[0]
            if (image.type === "image/jpeg" || image.type === "image/jpg" || image.type === "image/png" || image.type === "image/webp") {
                if (image.size / 1024 / 1024 <= 3) {
                    let reader = new FileReader()
                    reader.readAsDataURL(image)
                    reader.onloadend = e => setImagePreview([reader.result])
                    setimage(image)
                } else setImageError('File is too large to upload')
            } else setImageError('Unsupported file format (jpeg, jpg and png are supported)')
        } else setImageError('Please choose an image')

        imageElement.current.value = ''
    }

    const clearFields = () => {
        setName('')
        setNameError('')
        setDescription('')
        setDescriptionError('')
        setParentId('')
        setParentIdError('')
        setImageError('')
        setimage('')
        setImagePreview('')
    }

    const handleSave = () => {
        let error = false

        const data = {
            id: String(id).trim(),
            name: String(name).trim(),
            description: String(description).trim(),
            image: image,
            parentId: String(parentId).trim(),
        }

        if (data.name === '') {
            setNameError(req('Name'))
            error = true
        } else if (data.name.length > 50) {
            setNameError(tooLong('Name'))
            error = true
        }

        if (data.parentId === '') {
            setParentIdError(req('Parent'))
            error = true
        }

        if (type === ADD && data.image === '') {
            setImageError(req('Image'))
            error = true
        }

        if (!error) {
            if (type === ADD) {
                const formData = new FormData()

                formData.append('name', data.name)
                formData.append('description', data.description)
                formData.append('parentId', data.parentId)
                formData.append('image', image)

                setSaving(true)
                api.post('/category/', formData, superAdminReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        clearFields()
                    }
                }).catch(error => {
                    if (error.response) {
                        if (error.response.status === 409) setNameError(error.response.data.message)
                        else toast({ type: ERROR, message: error.response.data.message })
                    } else toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            } else if (type === EDIT) {
                if (imagePreview) handleSaveImage()
                setSaving(true)
                api.put('/category/?id=' + data.id + '&flag=updatecategory', data, superAdminReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    if (error.response) {
                        if (error.response.status === 409) setNameError(error.response.data.message)
                        else toast({ type: ERROR, message: error.response.data.message })
                    } else toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            }
        }
    }

    const handleSaveImage = () => {
        const data = new FormData()
        data.append('image', image)

        setSavingImage(true)
        api.post('/category/?id=' + id + '&flag=changeimage', data, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                toast({ type: SUCCESS, message: response.data.message })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setSavingImage(false))
    }

    useEffect(() => {
        getCategoryList()
    }, [])

    useEffect(() => {
        if (!isEmptyObj(category)) {
            setName(category.name)
            setDescription(category.description)
            setParentId(category.parentId)
        }
    }, [category])

    useEffect(() => {
        const editCategory = categoryList.filter(category => Number(category.id) === Number(id))[0]
        if (editCategory && !isEmptyObj(editCategory)) setCategory(editCategory)
    }, [categoryList, id])

    return <div className="container my-3">
        <div className="py-3 d-flex justify-content-between align-items-center">
            <h5 className="m-0">{title}</h5>
            {saving || savingImage ? <Link to="#" className="btn" onClick={e => e.preventDefault()}>Save</Link> :
                <Link to="#" className="btn btn-primary" onClick={e => preventClick(e, handleSave)}>Save</Link>}
        </div>
        <div className="row">
            <div className="col-12 col-md-6">
                <label htmlFor="name" className="small">Name</label>
                <input type="text" className={`form-control form-control-lg ${nameError !== '' && 'border-danger'}`} placeholder="Name" id="name" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handleName(e.target.value)} value={name} />
                <span className="text-danger my-2 fw-bold small">{nameError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6">
                <label htmlFor="parentId" className="small">Parent</label>
                {fetchingCategory ?
                    <input type="text" disabled readOnly className="form-control form-control-lg" placeholder="Please wait" /> :
                    <select className={`form-control form-control-lg ${parentIdError !== '' && 'border-danger'}`} id="parentId" required onChange={e => handleParentId(e.target.value)} value={parentId} >
                        <option value="" disabled>Select Parent</option>
                        <option value="0">Itself</option>
                        {categoryList.length > 0 && (type === EDIT ? categoryList.filter(cl => String(name) !== String(cl.parentName)) : categoryList).map(category => {
                            return <option value={category.id} key={nanoid()}>{category.name}</option>
                        })}
                    </select>}
                <span className="text-danger my-2 fw-bold small">{parentIdError}</span>
                <br />
            </div>
        </div>
        <div className="row">
            <div className="col-12 col-md-6">
                <textarea className="form-control resize-none" placeholder="Description" style={{ height: '200px' }} value={description} onChange={e => handleDescription(e.target.value)} />
                <span className="text-danger my-2 fw-bold small">{descriptionError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6">
                <label htmlFor="image" className={`form-control form-control-lg cursor-pointer d-flex align-items-center justify-content-center p-3 ${imageError !== '' && 'border-danger'}`} style={{ height: '200px' }}>
                    {imagePreview ? <img src={imagePreview} alt="" className="w-100 h-100 fit-contain" /> :
                        <span>
                            <i className="fa fa-image" />
                            <span className="ms-3">Choose Image</span>
                        </span>}
                </label>
                <span className="text-danger my-2 fw-bold small">{imageError}</span>
                <br />
                <input type="file" name="image" id="image" onChange={e => handleImage(e.target.files)} className="d-none" accept="image/*" ref={imageElement} />
            </div>
        </div>
    </div>
}

export default CategoryForm