import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { discountPrice, getCart, isEmptyObj, ratingAvg, ratingCount, setCart } from "../../include/function"
import { api, baseURL, customerReqConfig } from "../../include/api"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"
import { nanoid } from "nanoid"

const CustomerProduct = () => {
    const { id } = useParams()

    const [localCart, setLocalCart] = useState(getCart())
    const [fetchLoading, setFetchLoading] = useState(true)
    const [product, setProduct] = useState({})
    const [selectedImage, setSelectedImage] = useState('')
    const [images, setImages] = useState([])

    const getProduct = useCallback(() => {
        setFetchLoading(true)
        api.get('/product/?id=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setProduct(response.data.data[0])
            }
        }).catch(error => {
            setProduct([])
        }).finally(() => setFetchLoading(false))
    }, [id])

    const isInCart = () => localCart.filter(c => String(c.id) === String(id)).length > 0

    const handleAddToCart = (e) => {
        if (!isInCart()) {
            let cartItem = { id: id, qty: 1, }
            let cart = [...localCart, cartItem]
            setLocalCart(cart)
            setCart(cart)
        }
    }

    useEffect(() => {
        getProduct()
    }, [getProduct])

    useEffect(() => {
        if (!isEmptyObj(product)) {
            setImages(product.images ? [product.mainImage, ...product.images.map(image => image.imageUrl)] : [product.mainImage])
        }
    }, [product])

    return <div className="fixed-top-bar overflow-hidden">
        <CustomerHeader enableSearch enableButtons />
        <div className="container my-3">
            {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> :
                <div className="row">
                    <div className="col-12 col-xl-4 my-2">
                        <div className="w-100 border border-1 d-flex justify-content-between flex-column">
                            <div className="p-3">
                                <div className="row">
                                    <div className="col-3">
                                        {images.map(image => {
                                            const src = baseURL + '/' + image
                                            return <div className="box-sm border border-1 my-2 cursor-pointer hover-border overflow-hidden p-1 scale" onMouseEnter={e => setSelectedImage(src)} key={nanoid()}>
                                                <img src={src} className="w-100 h-100 fit-contain" alt="" />
                                            </div>
                                        })}
                                    </div>
                                    <div className="col-9">
                                        <img src={selectedImage !== '' ? selectedImage : baseURL + '/' + product.mainImage} className="w-100 fit-contain" alt="" style={{ minHeight: '50px', maxHeight: '500px' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center bg-light d-flex justify-content-around border-top">
                                {isInCart() ? <button className="btn btn-primary btn-lg btn-block" onClick={e => e.preventDefault()}>Added</button> :
                                    <button className="btn btn-primary btn-lg btn-block" onClick={e => handleAddToCart(e)}>Add to cart</button>}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-8 my-2">
                        <div className="px-3 py-2 border border-1">
                            <h4 className="my-2">{product.name}</h4>
                            {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0 ?
                                <h4 className="fw-bold">&#8377;{Math.ceil(product.price)}</h4> :
                                <div>
                                    <span className="h4 fw-bold">&#8377;{Math.ceil(discountPrice(product.price, product.discountValue, product.discountMode))}</span>
                                    <del className="h4 text-muted mx-3">&#8377;{Math.ceil(product.price)}</del>
                                    <span className="fw-bold text-success fs-6">{product.discountLabel}</span>
                                </div>}
                            <div className="badge bg-success">{product.rating} &#9733;</div>
                            <p className="mt-3 remove-margin-bottom" dangerouslySetInnerHTML={{ __html: product.description }} />
                            <hr />
                            <ul>
                                {(product.warrantyMode !== '' && Number(product.warrantyMode) !== 0) && <li><span className="my-1 small">{product.warrantyLabel}</span></li>}
                                {(product.deliveryCharge !== '' && Number(product.deliveryCharge) !== 0) && <li><span className="my-1 small">&#8377;{product.deliveryCharge} Delivery charge</span></li>}
                            </ul>
                        </div>
                        {product.ratingAndReview.length > 0 && <div className="mt-2 border border-1">
                            <div className="px-3 py-2  border-bottom">
                                <h6 className="m-0 text-muted">Ratings &amp; Reviews</h6>
                            </div>
                            <div className="px-3 py-2 border-bottom">
                                <div className="row">
                                    <div className="col d-flex align-items-center justify-content-center">
                                        <div className="p-4">
                                            <h1 className="text-dark text-center">{product.rating} &#9733;</h1>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="py-4">
                                            <p className=" mb-1">
                                                <span>5 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 5) + '%' }} className=" h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 5)}</span>
                                            </p>
                                            <p className=" mb-1">
                                                <span>4 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 4) + '%' }} className=" h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 4)}</span>
                                            </p>
                                            <p className=" mb-1">
                                                <span>3 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 3) + '%' }} className=" h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 3)}</span>
                                            </p>
                                            <p className=" mb-1">
                                                <span>2 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 2) + '%' }} className="bg-warning h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 2)}</span>
                                            </p>
                                            <p className=" mb-1">
                                                <span>1 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 1) + '%' }} className="bg-danger h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 1)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {product.ratingAndReview.map((rr, index) => {
                                return rr.review && Number(rr.rating) !== 0 && <div className="px-3 py-2 border-bottom" key={nanoid()}>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div color="success">{rr.rating} &#9733;</div>
                                        <span className="text-dark fw-bold  ms-2">{rr.name}</span>
                                    </div>
                                    <p className="m-0 my-2">{rr.review}</p>
                                </div>
                            })}
                        </div>}
                    </div>
                </div>}
        </div>
    </div>
}

export default CustomerProduct