import React, { useCallback, useEffect, useRef, useState } from "react"
import { api, superAdminReqConfig } from "../../include/api"
import { isEmptyObj, preventClick, req } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Spinner from "../add-ons/Spinner"
import { ADD, EDIT, ERROR, SUCCESS } from "../../include/constant"
import { Link } from "react-router-dom"
import { nanoid } from "nanoid"

const SuperAdminAddSlide = ({ type, title, id }) => {
    const toast = useNotification()

    const imageElement = useRef()

    const [saving, setSaving] = useState(false)
    const [fetchingSlide, setFetchingSlide] = useState(type === EDIT)
    const [fetchingProducts, setFetchingProducts] = useState(false)
    const [fetchingShops, setFetchingShops] = useState(false)

    const [shopId, setShopId] = useState('')
    const [productId, setProductId] = useState('')
    const [image, setimage] = useState('')
    const [imagePreview, setImagePreview] = useState('')
    const [status, setStatus] = useState('')
    const [shopIdError, setShopIdError] = useState('')
    const [productIdError, setProductIdError] = useState('')
    const [imageError, setImageError] = useState('')
    const [statusError, setStatusError] = useState('')

    const [slide, setSlide] = useState([])
    const [shops, setShops] = useState([])
    const [products, setProducts] = useState([])

    const getSlide = useCallback((afterGet) => {
        setFetchingSlide(true)
        api.get('/slide_show/?id=' + id, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setSlide(response.data.data[0])
                if (afterGet) afterGet(response.data.data[0])
            }
        }).catch(error => {
            setSlide([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchingSlide(false))
    }, [id])

    const getShops = (afterGet) => {
        setFetchingShops(true)
        api.get('/shop/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setShops(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setShops([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchingShops(false))
    }

    const getProducts = useCallback((afterGet) => {
        setFetchingProducts(true)
        api.get('/product/?shopId=' + shopId, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setProducts(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setProducts([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchingProducts(false))
    }, [shopId])

    const handleShopId = (value) => {
        setShopIdError('')
        setShopId(value)
    }

    const handleProductId = (value) => {
        setProductIdError('')
        setProductId(value)
    }

    const handleStatus = (value) => {
        setStatusError('')
        setStatus(value)
    }

    const handleImage = (image) => {
        setImageError('')
        if (image.length > 0) {
            image = image[0]
            if (image.type === "image/jpeg" || image.type === "image/jpg" || image.type === "image/png" || image.type === "image/webp") {
                if (image.size / 1024 / 1024 <= 3) {
                    let reader = new FileReader()
                    reader.readAsDataURL(image)
                    reader.onloadend = e => setImagePreview([reader.result])
                    setimage(image)
                } else setImageError('File is too large to upload')
            } else setImageError('Unsupported file format (jpeg, jpg and png are supported)')
        } else setImageError('Please choose an image')

        imageElement.current.value = ''
    }

    const clearFields = () => {
        setShopId('')
        setProductId('')
        setimage('')
        setImagePreview('')
        setStatus('')

        setShopIdError('')
        setProductIdError('')
        setImageError('')
        setStatusError('')
    }

    const handleSave = () => {
        let error = false
        const formData = new FormData()

        const data = {
            id: String(id).trim(),
            shopId: String(shopId).trim(),
            productId: String(productId).trim(),
            image: image,
            text: '',
            status: String(status).trim(),
        }

        formData.append('shopId', data.shopId)
        formData.append('productId', data.productId)
        formData.append('text', data.text)
        formData.append('image', image)
        formData.append('status', data.status)

        if (data.shopId === '') {
            setShopIdError(req('Shop'))
            error = true
        }

        if (data.productId === '') {
            setProductIdError(req('Product'))
            error = true
        }

        if (type === ADD && data.image === '') {
            setImageError(req('Image'))
            error = true
        }

        if (data.status === '') {
            setStatusError(req('Status'))
            error = true
        }

        if (!error) {
            if (type === ADD) {
                setSaving(true)
                api.post('/slide_show/', formData, superAdminReqConfig()).then(response => {
                    if (response.status === 200) {
                        clearFields()
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            } else if (type === EDIT) {
                setSaving(true)
                api.post('/slide_show/?id=' + data.id, formData, superAdminReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            }
        }
    }

    // const handleDelete = (e) => {
    //     e.preventDefault()
    //     setDeleteLoading(true)
    //     api.delete('/slide_show/?id=' + id, superAdminReqConfig()).then(response => {
    //         if (response.status === 200) {
    //             getSlide(() => {
    //                 toast({ type: SUCCESS, message: response.data.message })
    //             })
    //         }
    //     }).catch(error => {
    //         error.response ?
    //             toast({ type: ERROR, message: error.response.data.message }) :
    //             toast({ type: ERROR, message: error.message })
    //     }).finally(() => setDeleteLoading(false))
    // }

    useEffect(() => {
        if (type === EDIT) getSlide()
    }, [getSlide, type])

    useEffect(() => {
        getShops()
    }, [])

    useEffect(() => {
        if (shopId !== '') getProducts()
    }, [shopId, getProducts])

    useEffect(() => {
        if (!isEmptyObj(slide)) {
            setShopId(slide.shopId || '')
            setProductId(slide.productId || '')
            setStatus(slide.status || '')
        }
    }, [slide])

    return <div className="container my-3">
        <div className="py-3 d-flex justify-content-between align-items-center">
            <h5 className="m-0">{title}</h5>
            {saving ? <Link to="#" className="btn" onClick={e => e.preventDefault()}>Save</Link> :
                <Link to="#" className="btn btn-primary" onClick={e => preventClick(e, handleSave)}>Save</Link>}
        </div>
        {fetchingSlide ? <Spinner color="danger" /> : <>
            <div className="row">
                <div className="col-12 col-md-3">
                    <label htmlFor="shopId" className="small">Shop</label>
                    {fetchingShops ?
                        <input type="text" placeholder="Please wait..." disabled readOnly className="form-control form-control-lg" /> :
                        <select className={`form-control form-control-lg ${shopIdError !== '' && 'border-danger'}`} id="state" required onChange={e => handleShopId(e.target.value)} value={shopId} >
                            <option value="" disabled>Select Shop</option>
                            {shops && shops.length > 0 && shops.map((shop, index) => {
                                return <option value={shop.id} key={nanoid()}>{shop.name}</option>
                            })}
                        </select>}
                    <span className="text-danger my-2 small fw-bold">{shopIdError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-3">
                    <label htmlFor="productId" className="small">Product</label>
                    {fetchingProducts ?
                        <input type="text" placeholder="Please wait..." disabled readOnly className="form-control form-control-lg" /> :
                        <select className={`form-control form-control-lg ${productIdError !== '' && 'border-danger'}`} id="state" required onChange={e => handleProductId(e.target.value)} value={productId} >
                            <option value="" disabled>Select Product</option>
                            {products && products.length > 0 && products.map((product, index) => {
                                return <option value={product.id} key={nanoid()}>{product.name}</option>
                            })}
                        </select>}
                    <span className="text-danger my-2 small fw-bold">{productIdError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label className="small">Status</label>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="active" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${status === 'active' ? 'bg-success text-white fw-bold' : ''}`}>
                                <span>Active</span>
                                <input type="radio" className="form-check-input" name="status" id="active" checked={status === 'active'} onChange={e => handleStatus('active')} />
                            </label>
                        </div>
                        <div className="col-6">
                            <label htmlFor="inactive" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${status === 'inactive' ? 'bg-danger text-white fw-bold' : ''}`}>
                                <span>Inactive</span>
                                <input type="radio" className="form-check-input" name="status" id="inactive" checked={status === 'inactive'} onChange={e => handleStatus('inactive')} />
                            </label>
                        </div>
                    </div>
                    <span className="text-danger my-2 small fw-bold">{statusError}</span>
                    <br />
                </div>
                <div className="col-12">
                    <label htmlFor="image" className={`form-control form-control-lg cursor-pointer d-flex align-items-center justify-content-center p-3 ${imageError !== '' && 'border-danger'}`} style={{ height: '200px' }}>
                        {imagePreview ? <img src={imagePreview} alt="" className="w-100 h-100 fit-contain" /> :
                            <span>
                                <i className="fa fa-image" />
                                <span className="ms-3">Choose Image</span>
                            </span>}
                    </label>
                    <span className="text-danger my-2 fw-bold small">{imageError}</span>
                    <br />
                    <input type="file" name="image" id="image" onChange={e => handleImage(e.target.files)} className="d-none" accept="image/*" ref={imageElement} />
                </div>
            </div>
        </>}
    </div>
}

export default SuperAdminAddSlide