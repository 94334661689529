import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, superAdminReqConfig } from "../../include/api"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import SuperAdminHeader from "./Header"

const SuperAdminShop = () => {
    const [fetchLoading, setFetchLoading] = useState(true)
    const [shopList, setShopList] = useState([])

    const getShops = (afterGet) => {
        setFetchLoading(true)
        api.get('/shop/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setShopList(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setShopList([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchLoading(false))
    }

    useEffect(() => {
        getShops()
    }, [])

    return <div className="fixed-top-bar">
        <SuperAdminHeader />
        <div className="container my-3">
            <div className="py-3 d-flex justify-content-between align-items-center">
                <h5 className="m-0">Shop</h5>
                <Link to="/super/add-shop" className="btn btn-success" >Add new shop</Link>
            </div>
            {fetchLoading ?
                <Spinner color="danger" spinnerSize="lg" /> :
                shopList.length > 0 ?
                    <div className="row">
                        {shopList.map((shop, index) => {
                            return <Shop shop={shop} key={nanoid()} />
                        })}
                    </div> : <Error mainText="No shop found!" secondaryText={<Link to="/super/add-shop">Add New shop</Link>} />}
        </div>
    </div>
}

const Shop = ({ shop }) => {
    return <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
        <div className="border shadow-sm p-2">
            <h6 className="d-flex align-items-center justify-content-between fw-bold">
                <div className="text-truncate">
                    <i className={`fas fa-circle blink ${shop.status === 'active' ? 'text-success' : 'text-danger'}`} style={{ fontSize: '.7rem' }} />
                    <span className="ps-2">{shop.name}</span>
                </div>
                <Link to={`/super/edit-shop/${shop.id}`} className="small d-flex align-items-center hover-underline">Edit <i className="fas fa-pencil-alt fa-sm ps-2" />
                </Link>
            </h6>
            <hr className="my-2" />
            <p className="small mb-1 text-muted d-flex align-items-center">
                <i className="fas fa-phone-alt fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{shop.phoneNumber}</span>
            </p>
            <p className="small mb-1 text-muted d-flex align-items-center">
                <i className="fas fa-envelope fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{shop.emailId}</span>
            </p>
            <p className="small mb-1 text-muted d-flex">
                <i className="fas fa-map-marker-alt fa-md fa-fw text-primary pt-1" />
                <span className="ps-2">{shop.area}<br />{shop.address}<br />{shop.pin}</span>
            </p>
        </div>
    </div>
}

export default SuperAdminShop