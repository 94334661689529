import React, { useState } from "react"
import { nanoid } from "nanoid"
import { Link, useLocation } from "react-router-dom"
import SideNavBar from "./SideNavBar"

const Header = ({ homeLink, menuContent }) => {
    const [toggleClass, setToggleClass] = useState(false)
    return <div>
        <div className="d-block d-lg-none">
            <SideNavBar
                content={menuContent}
                setToggleClass={setToggleClass}
                toggleClass={toggleClass}
            />
        </div>
        <header className="w-100 overflow-hidden bg-primary p-2 fixed-top">
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col d-flex align-items-center h-100">
                        <i className="d-block d-lg-none fas fa-bars h3 m-0 cursor-pointer text-white mx-1 me-3" onClick={() => setToggleClass('open')} />
                        <h4 className="m-0 d-flex align-items-center justify-content-center h-100 text-truncate">
                            <Link to={homeLink} className="text-white fw-bold text-uppercase">My Shop!</Link>
                        </h4>
                    </div>
                    <div className="col h-100">
                        <div className="d-none d-lg-block h-100">
                            <div className="d-flex align-items-center h-100">
                                {menuContent.map(menu => <MenuContent
                                    menu={menu}
                                    key={nanoid()}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
}

const MenuContent = ({ menu }) => {
    const { pathname } = useLocation()
    return <Link to={menu.url} className={`${menu.url === pathname ? 'bg-danger rounded' : ''} text-white small fw-bold text-uppercase text-truncate py-2 px-3 d-inline-block`}>{menu.text}</Link>
}
export default Header