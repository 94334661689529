import axios from 'axios'
import { getAdminToken, getCustomerToken, getSuperAdminToken } from './function'

export const baseURL = 'https://cart.dhanyavad.co.in/api'

export const api = axios.create({ baseURL: baseURL })

export const customerReqConfig = () => ({
    headers: {
        Authorization: getCustomerToken()
    },
})

export const adminReqConfig = () => ({
    headers: {
        Authorization: getAdminToken()
    },
})

export const superAdminReqConfig = () => ({
    headers: {
        Authorization: getSuperAdminToken()
    },
})