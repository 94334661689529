import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, baseURL, adminReqConfig } from "../../include/api"
import { getAdmin } from "../../include/function"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import AdminHeader from "./Header"

const AdminProduct = () => {
    const user = getAdmin()

    const [fetchingProducts, setFetchingProducts] = useState(true)
    const [productList, setProductList] = useState([])

    const getProducts = useCallback((afterGet) => {
        setFetchingProducts(true)
        api.get('/product/?shopId=' + user.id, adminReqConfig()).then(response => {
            if (response.status === 200) {
                setProductList(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setProductList([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchingProducts(false))
    }, [user.id])

    useEffect(() => {
        getProducts()
    }, [getProducts])

    return <div className="fixed-top-bar">
        <AdminHeader />
        <div className="container my-3">
            <div className="py-3 d-flex justify-content-between align-items-center">
                <h5 className="m-0">Product</h5>
                <Link to="/admin/add-product" className="btn btn-success" >Add new product</Link>
            </div>
            {fetchingProducts ?
                <Spinner color="danger" spinnerSize="lg" /> :
                productList.length > 0 ?
                    <div className="row">
                        {productList.map((product, index) => {
                            return <Product product={product} key={nanoid()} />
                        })}
                    </div> : <Error mainText="No product found!" secondaryText={<Link to="/admin/add-product">Add New product</Link>} />}
        </div>
    </div>
}

const Product = ({ product }) => {
    return <div className="col-12 col-sm-6 col-lg-4 p-2">
        <div className="border shadow-sm w-100 h-100 d-block">
            <div className="img-md w-100 p-3 border-bottom d-flex justify-content-center">
                <img src={baseURL + "/" + product.mainImage} alt="" className="fit-contain w-100 h-100" />
            </div>
            <div className="p-2">
                <h6 className="d-flex align-items-center justify-content-between fw-bold">
                    <div className="text-truncate">
                        <i className={`fas fa-circle blink ${product.status === 'active' ? 'text-success' : 'text-danger'}`} style={{ fontSize: '.7rem' }} />
                        <span className="ps-2">{product.name}</span>
                    </div>
                    <Link to={`/admin/edit-product/${product.id}`} className="small d-flex align-items-center hover-underline">Edit <i className="fas fa-pencil-alt fa-sm ps-2" />
                    </Link>
                </h6>
                <div className="mb-1 text-truncate ps-2">
                    <span className="badge bg-success">{product.rating} &#9733;</span>
                    <span className="small text-muted fw-bold ms-3">&#8377;{product.price}</span>
                </div>
                <ul className="small text-muted ps-2 m-0">
                    <li className="text-truncate">Delivery Charge: {(product.deliveryCharge && product.deliveryCharge !== 0) ? <>&#8377;{product.deliveryCharge}</> : 'FREE'}</li>
                    {product.discountValue !== 0 && product.discountLabel && <li className="text-truncate">{product.discountLabel}</li>}
                    {product.warrantyPeriod !== 0 && product.warrantyLabel && <li className="text-truncate">{product.warrantyLabel}</li>}
                </ul>
            </div>
            <div className="p-2 hide-scrollbar" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                {product.images && product.images.length > 0 && product.images.map(image => <ProductImage
                    src={baseURL + '/' + image.imageUrl}
                    key={nanoid()}
                />)}
            </div>
        </div>
    </div>
}

const ProductImage = ({ src }) => {
    return <div className="form-control me-2 p-0 d-inline-block" style={{ width: '60px', height: '60px' }}>
        <img src={src} alt="" className="fit-contain w-100 h-100" />
    </div>
}

export default AdminProduct