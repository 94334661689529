import React from "react"
import Header from "../common/Header"

const AdminHeader = () => {
    const homeLink = '/admin'
    const menuContent = [
        {
            url: "/admin/order",
            text: "Order",
            icon: "shopping-cart"
        },
        {
            url: "/admin/product",
            text: "Product",
            icon: "gifts"
        },
        {
            url: "/admin/profile",
            text: "Profile",
            icon: "user"
        },
        {
            url: "/admin/logout",
            text: "Logout",
            icon: "sign-out-alt"
        }
    ]

    return <Header
        homeLink={homeLink}
        menuContent={menuContent}
    />
}

export default AdminHeader
