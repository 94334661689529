import React, { useState } from "react"
import { Link } from "react-router-dom"
import { api } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { preventClick, validateEmail, validatePhoneNumber } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"

const CustomerSignup = ({ handleCustomer }) => {
    const toast = useNotification()

    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [emailId, setEmailId] = useState('')

    const [userIdError, setUserIdError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [nameError, setNameError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [emailIdError, setEmailIdError] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [saving, setSaving] = useState(false)

    const handleUserId = (value) => {
        setUserIdError('')
        setUserId(value)
    }

    const handlePassword = (value) => {
        setPasswordError('')
        setPassword(value)
    }

    const handleName = (value) => {
        setNameError('')
        setName(value)
    }

    const handlePhoneNumber = (value) => {
        setPhoneNumberError('')
        setPhoneNumber(value)
    }

    const handleEmailId = (value) => {
        setEmailIdError('')
        setEmailId(value)
    }

    const handleShowPassword = () => setShowPassword(!showPassword)

    const handleSignup = () => {
        let error = false
        const data = {
            userId: String(userId).trim().toLowerCase(),
            password: String(password),
            name: String(name).trim(),
            phoneNumber: String(phoneNumber).trim(),
            emailId: String(emailId).trim().toLowerCase(),
        }

        if (data.userId === '') {
            setUserIdError('Username is required')
        }

        if (data.password === '') {
            setPasswordError('Password required')
            error = true
        } else if (data.password.length < 6) {
            setPasswordError('Password must be at least 6 characters')
            error = true
        }

        if (data.name === '') {
            setNameError('Name required')
            error = true
        }

        if (data.phoneNumber === '') {
            setPhoneNumberError('Phone number required')
            error = true
        } else if (!validatePhoneNumber(data.phoneNumber)) {
            setPhoneNumberError('Invalid phone number')
            error = true
        }

        if (data.emailId !== '' && !validateEmail(data.emailId)) {
            setEmailIdError('Invalid email address')
            error = true
        }

        if (!error) {
            setSaving(true)
            api.post('/signup/', data).then(response => {
                if (response.status === 200) {
                    let customer = response.data.data.user
                    let token = response.data.data.token
                    handleCustomer(customer, token)
                    toast({ type: SUCCESS, message: response.data.message })
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally(() => setSaving(false))
        }
    }

    return <div className="p-4">
        <div className="container">
            <h2 className="my-0">Welcome Back</h2>
            <p className="text-50">Sign in to continue</p>
            <form className="mt-5 mb-4" action="#">
                <div className="form-group mb-4">
                    <label htmlFor="username" className="">Username</label>
                    <input type="text" placeholder="Enter Username" className={`border border-1 rounded w-100 p-2 form-control ${userIdError !== '' ? 'border-danger' : ''}`} id="username" onChange={e => handleUserId(e.target.value)} value={userId} autoComplete="off" />
                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{userIdError}</div>
                    <br />
                </div>
                <div className="form-group mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <label htmlFor="password" className="">Password</label>
                        <Link to="#" className="pe-3" onClick={e => preventClick(e, handleShowPassword)} tabIndex="-1">{showPassword ? 'Hide' : 'Show'} Password <i className={`fas fa-eye${showPassword ? '-slash' : ''}`} /> </Link>
                    </div>
                    <div className="position-relative w-100 h-100">
                        <input type={showPassword ? "text" : "password"} placeholder="Enter Password" className={`border border-1 rounded w-100 p-2 form-control ${passwordError !== '' ? 'border-danger' : ''}`} id="password" onChange={e => handlePassword(e.target.value)} value={password} />
                    </div>
                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{passwordError}</div>
                    <br />
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="name" className="">Name</label>
                    <input type="text" placeholder="Enter Name" className={`border border-1 rounded w-100 p-2 form-control ${nameError !== '' ? 'border-danger' : ''}`} id="name" onChange={e => handleName(e.target.value)} value={name} autoComplete="off" />
                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{nameError}</div>
                    <br />
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="phoneNumber" className="">Phone Number</label>
                    <input type="number" placeholder="Enter Phone Number" className={`border border-1 rounded w-100 p-2 form-control ${phoneNumberError !== '' ? 'border-danger' : ''}`} id="phoneNumber" onChange={e => handlePhoneNumber(e.target.value)} value={phoneNumber} autoComplete="off" />
                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{phoneNumberError}</div>
                    <br />
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="emailId" className="">Email Id</label>
                    <input type="text" placeholder="Enter Email Id" className={`border border-1 rounded w-100 p-2 form-control ${emailIdError !== '' ? 'border-danger' : ''}`} id="emailId" onChange={e => handleEmailId(e.target.value)} value={emailId} autoComplete="off" />
                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{emailIdError}</div>
                    <br />
                </div>
                {saving ? <button className="btn border btn-lg btn-block font-decoration-none text-uppercase no-drop" onClick={e => e.preventDefault()}>SIGN UP</button> :
                    <button className="btn btn-primary btn-lg btn-block font-decoration-none text-uppercase" onClick={e => preventClick(e, handleSignup)}>SIGN UP</button>}
                <br />
                <Link to="/login" className="small fw-bold">Alreay a customer? Login here</Link>
            </form>
        </div>
    </div>
}

export default CustomerSignup