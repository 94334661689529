import React from "react"
import { ADD } from "../../include/constant"
import CategoryForm from "../common/CategoryForm"
import SuperAdminHeader from "./Header"

const SuperAdminAddCategory = () => {
    return <div className="fixed-top-bar">
        <SuperAdminHeader />
        <CategoryForm
            title="Add Category"
            type={ADD}
        />
    </div>
}

export default SuperAdminAddCategory