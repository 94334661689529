import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, superAdminReqConfig } from "../../include/api"
import { formateDate } from "../../include/function"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import SuperAdminHeader from "./Header"

const SuperAdminVoucher = () => {
    const [fetchLoading, setFetchLoading] = useState(true)
    const [vouchers, setVouchers] = useState([])

    const getVouchers = (afterGet) => {
        setFetchLoading(true)
        api.get('/voucher/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setVouchers(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setVouchers([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchLoading(false))
    }

    useEffect(() => {
        getVouchers()
    }, [])

    return <div className="fixed-top-bar">
        <SuperAdminHeader />
        <div className="container my-3">
            <div className="py-3 d-flex justify-content-between align-items-center">
                <h5 className="m-0">Voucher</h5>
                <Link to="/super/add-voucher" className="btn btn-success" >Add new voucher</Link>
            </div>
            {fetchLoading ?
                <Spinner color="danger" spinnerSize="lg" /> :
                vouchers.length > 0 ?
                    <div className="row">
                        {vouchers.map((voucher, index) => {
                            return <Voucher voucher={voucher} key={nanoid()} />
                        })}
                    </div> : <Error mainText="No voucher found!" secondaryText={<Link to="/super/add-voucher">Add New voucher</Link>} />}
        </div>
    </div>
}

const Voucher = ({ voucher }) => {
    return <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
        <div className="border shadow-sm p-2">
            <h6 className="d-flex align-items-center justify-content-between fw-bold">
                <div className="text-truncate">
                    <i className={`fas fa-circle blink ${voucher.status === 'active' ? 'text-success' : 'text-danger'}`} style={{ fontSize: '.7rem' }} />
                    <span className="ps-2">{voucher.code}</span>
                </div>
                <Link to={`/super/edit-voucher/${voucher.id}`} className="small d-flex align-items-center hover-underline">Edit <i className="fas fa-pencil-alt fa-sm ps-2" />
                </Link>
            </h6>
            <hr className="my-2" />
            <p className="small mb-1 text-muted d-flex align-items-center">
                <i className="fas fa-stopwatch-20 fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{voucher.currentCount} / {voucher.maxCount}</span>
            </p>
            <p className="small mb-1 text-muted d-flex align-items-center">
                <i className="fas fa-tags fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{voucher.text}</span>
            </p>
            <p className="small mb-1 text-muted d-flex align-items-center">
                <i className="fas fa-clock fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{formateDate(voucher.validity)}</span>
            </p>
        </div>
    </div>
}

export default SuperAdminVoucher