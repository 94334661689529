import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { adminReqConfig, api, baseURL } from "../../include/api"
import { CANCELLED, CANCEL_REQUEST, DELIVERED, ORDERED, PACKED, RETURNED, RETURN_APPROVED, RETURN_REQUEST, RETURN_SHIPPED, SHIPPED } from "../../include/constant"
import { getAdmin, preventClick, today } from "../../include/function"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import AdminHeader from "./Header"

const AdminOrder = () => {
    const user = getAdmin()

    const [loading, setLoading] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [orders, setOrders] = useState([])
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [status, setStatus] = useState('')

    const handleFilter = () => setShowFilter(!showFilter)

    const getOrders = useCallback((afterGet) => {
        setLoading(true)
        api.get('/orders/?shopId=' + user.id + '&fromDate=' + fromDate + '&toDate=' + toDate + '&status=' + status, adminReqConfig()).then(response => {
            if (response.status === 200) {
                setOrders(response.data.data)
                if (afterGet) afterGet()
            }
        }).catch(error => {
            setOrders([])
        }).finally(() => setLoading(false))
    }, [fromDate, status, toDate, user.id])

    useEffect(() => {
        if (user.id && user.id !== '' && fromDate !== '' && toDate !== '') {
            getOrders()
        }
    }, [fromDate, getOrders, toDate, user.id])

    useEffect(() => {
        setFromDate(today())
        setToDate(today())
    }, [])

    return <div className="fixed-top-bar">
        <AdminHeader />
        <div className="container my-3">
            <div className="row">
                <div className="col">
                    <h5 className="mb-3">Orders</h5>
                </div>
                <div className="col text-end">
                    <button className="btn btn-primary d-md-none" onClick={e => preventClick(e, handleFilter)}>
                        <i className="fas fa-filter fa-fw me-2" />
                        <span>Filter</span>
                    </button>
                </div>
            </div>
            <div className={`${!showFilter ? 'd-none' : ''} d-md-block`}>
                <div className="row my-3">
                    <div className="col-12 col-md-4 mb-3">
                        <label htmlFor="fromDate">From Date</label>
                        <input type="date" className="form-control form-control-lg" name="fromDate" id="fromDate" value={fromDate} onChange={e => setFromDate(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <label htmlFor="toDate">To Date</label>
                        <input type="date" className="form-control form-control-lg" name="toDate" id="toDate" value={toDate} onChange={e => setToDate(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <label htmlFor="fromDate">status</label>
                        <select name="status" className="form-control form-control-lg text-capitalize" id="status" onChange={e => setStatus(e.target.value)} value={status}>
                            <option value="">All</option>
                            <option value={ORDERED}>{ORDERED}</option>
                            <option value={PACKED}>{PACKED}</option>
                            <option value={SHIPPED}>{SHIPPED}</option>
                            <option value={DELIVERED}>{DELIVERED}</option>
                            <option value={RETURN_REQUEST}>{RETURN_REQUEST}</option>
                            <option value={RETURN_APPROVED}>{RETURN_APPROVED}</option>
                            <option value={RETURN_SHIPPED}>{RETURN_SHIPPED}</option>
                            <option value={RETURNED}>{RETURNED}</option>
                            <option value={CANCEL_REQUEST}>{CANCEL_REQUEST}</option>
                            <option value={CANCELLED}>{CANCELLED}</option>
                        </select>
                    </div>
                </div>
            </div>
            {loading ? <div className="py-5"><Spinner color="danger" /></div> :
                <div className="row">
                    {orders.length > 0 ?
                        orders.map(order => order.orderItems.map(item => {
                            return <Order key={nanoid()} item={item} />
                        })) : <Error mainText="No orders found!" />}
                </div>}
        </div>
    </div>
}

const Order = ({ item }) => {
    return <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4">
        <Link to={"/admin/order-details/" + item.id} className="text-center d-block p-3 border my-2 text-dark">
            <img className="fit-contain h-100 hover-scale" style={{ minWidth: '50px', maxWidth: '100px' }} src={item.mainImage && baseURL + '/' + item.mainImage} alt="" />
            <h6 className="my-2 text-truncate small">{item.productName}</h6>
            <span className="small me-3">&#8377;{Math.ceil(item.amount + item.deliveryCharge)}</span>
            <i className="fas fa-circle fa-fw text-success blink me-1" style={{ fontSize: '.7rem' }} />
            <span className="text-capitalize">{item.status}</span>
        </Link>
    </div>
}

export default AdminOrder