import React from "react"
import { ADD } from "../../include/constant"
import ProductForm from "../common/ProductForm"
import AdminHeader from "./Header"

const AdminAddProduct = () => {
    return <div className="fixed-top-bar">
        <AdminHeader />
        <ProductForm
            title="Add Product"
            type={ADD}
        />
    </div>
}

export default AdminAddProduct