import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { api, baseURL, adminReqConfig } from "../../include/api"
import { APPROVE, CANCELLED, CANCEL_REQUEST, DECLINE, DELIVERED, ERROR, ORDERED, PACKED, RETURNED, RETURN_APPROVED, RETURN_RECEIVED, RETURN_REQUEST, RETURN_SHIPPED, SHIPPED, SUCCESS } from "../../include/constant"
import { formateDate, getAdmin, preventClick } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Modal from "../add-ons/Modal"
import Spinner from "../add-ons/Spinner"
import YesOrNo from "../add-ons/YesOrNo"
import AdminHeader from "./Header"

const AdminOrderDetails = () => {
    const toast = useNotification()
    const { push } = useHistory()
    const user = getAdmin()
    const { id } = useParams()

    const [packedYesOrNo, setPackedYesOrNo] = useState(false)
    const [shippedModal, setShippedModal] = useState(false)
    const [deliveredModal, setDeliveredModal] = useState(false)
    const [returnShippedModal, setReturnShippedModal] = useState(false)
    const [returnReceivedModal, setReturnReceivedModal] = useState(false)
    const [approveYesOrNo, setApproveYesOrNo] = useState(false)
    const [returnApproveYesOrNo, setReturnApproveYesOrNo] = useState(false)
    const [showDeclineModal, setShowDeclineModal] = useState(false)
    const [showReturnDeclineModal, setShowReturnDeclineModal] = useState(false)
    const [putLoading, setPutLoading] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(true)
    const [reason, setReason] = useState('')
    const [shippingInfo, setShippingInfo] = useState('')
    const [deliveredAt, setDeliveredAt] = useState('')
    const [returnShippedAt, setReturnShippedAt] = useState('')
    const [returnReceivedAt, setReturnReceivedAt] = useState('')
    const [status, setStatus] = useState('')
    const [orderItem, setOrderItem] = useState({})

    const getOrderItem = useCallback((afterGet) => {
        setFetchLoading(true)
        api.get('/orders/?orderItemId=' + id + '&shopId=' + user.id, adminReqConfig()).then(response => {
            if (response.status === 200) {
                setOrderItem(response.data.data[0])
                if (afterGet) afterGet()
            }
        }).catch(error => {
            push('/admin/orders')
        }).finally(() => {
            setFetchLoading(false)
        })
    }, [id, user.id, push])

    const handlePacked = () => {
        setStatus(PACKED)
        setPackedYesOrNo(true)
    }

    const handleShipped = () => {
        setStatus(SHIPPED)
        setShippedModal(true)
    }

    const handleDelivered = () => {
        setStatus(DELIVERED)
        setDeliveredModal(true)
    }

    const handleReturnShipped = () => {
        setStatus(RETURN_SHIPPED)
        setReturnShippedModal(true)
    }

    const handleReturnReceived = () => {
        setStatus(RETURNED)
        setReturnReceivedModal(true)
    }

    const handleApprove = () => {
        setStatus(APPROVE)
        setApproveYesOrNo(true)
    }

    const handleDecline = () => {
        setStatus(DECLINE)
        setShowDeclineModal(true)
    }

    const handleReturnApprove = () => {
        setStatus(APPROVE)
        setReturnApproveYesOrNo(true)
    }

    const handleReturnDecline = () => {
        setStatus(DECLINE)
        setShowReturnDeclineModal(true)
    }

    const handleApproveYes = () => approveOrDecline()
    const handleReturnApproveYes = () => returnApproveOrDecline()
    const handleYes = () => changeStatus()

    const setDefault = () => {
        setPackedYesOrNo(false)
        setShippedModal(false)
        setDeliveredModal(false)
        setReturnShippedModal(false)
        setReturnReceivedModal(false)
        setApproveYesOrNo(false)
        setReturnApproveYesOrNo(false)
        setShowDeclineModal(false)
        setShowReturnDeclineModal(false)
        setReason('')
        setShippingInfo('')
    }

    const approveOrDecline = () => {
        const data = {
            status: String(status).trim(),
            adminComment: String(reason).trim()
        }

        setPutLoading(true)
        api.put('/orders/?id=' + id + '&flag=approveOrDecline', data, adminReqConfig()).then(response => {
            if (response.status === 200) {
                getOrderItem(() => {
                    setDefault()
                    toast({ type: SUCCESS, message: response.data.message })
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPutLoading(false))
    }

    const returnApproveOrDecline = () => {
        const data = {
            status: String(status).trim(),
            adminComment: String(reason).trim()
        }

        setPutLoading(true)
        api.put('/orders/?id=' + id + '&flag=returnApproveOrDecline', data, adminReqConfig()).then(response => {
            if (response.status === 200) {
                getOrderItem(() => {
                    setDefault()
                    toast({ type: SUCCESS, message: response.data.message })
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPutLoading(false))
    }

    const changeStatus = () => {
        const data = {
            status: String(status).trim(),
            deliveredAt: String(deliveredAt).trim() !== '' ? formateDate(deliveredAt) : null,
            returnShippedAt: String(returnShippedAt).trim() !== '' ? formateDate(returnShippedAt) : null,
            returnReceivedAt: String(returnReceivedAt).trim() !== '' ? formateDate(returnReceivedAt) : null,
            deliveryInfo: String(shippingInfo).trim(),
        }

        setPutLoading(true)
        api.put('/orders/?id=' + id + '&flag=changestatus', data, adminReqConfig()).then(response => {
            if (response.status === 200) {
                getOrderItem(() => {
                    setDefault()
                    toast({ type: SUCCESS, message: response.data.message })
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPutLoading(false))
    }

    useEffect(() => {
        getOrderItem()
    }, [getOrderItem])

    return <div className="fixed-top-bar">
        <div className="position-relative">
            {packedYesOrNo && <YesOrNo
                zIndex={1040}
                handleYes={handleYes}
                handleNo={() => setPackedYesOrNo(false)}
                headerText="Packed?"
                yesBtnText="Yes"
                loading={putLoading}
            />}
            {shippedModal && <Modal
                zIndex={1040}
                component={<Shipped
                    changeStatus={changeStatus}
                    putLoading={putLoading}
                    setShippingInfo={setShippingInfo}
                    shippingInfo={shippingInfo}
                />}
                handleClose={() => setShippedModal(false)}
                headerText="Shipped?"
            />}
            {deliveredModal && <Modal
                zIndex={1040}
                component={<Delivered
                    changeStatus={changeStatus}
                    deliveredAt={deliveredAt}
                    putLoading={putLoading}
                    setDeliveredAt={setDeliveredAt}
                />}
                handleClose={() => setDeliveredModal(false)}
                headerText="Delivered?"
            />}
            {returnShippedModal && <Modal
                zIndex={1040}
                component={<ReturnShipped
                    changeStatus={changeStatus}
                    putLoading={putLoading}
                    returnShippedAt={returnShippedAt}
                    setReturnShippedAt={setReturnShippedAt}
                />}
                handleClose={() => setReturnShippedModal(false)}
                headerText="Return Shipped?"
            />}
            {returnReceivedModal && <Modal
                zIndex={1040}
                component={<ReturnReceived
                    changeStatus={changeStatus}
                    putLoading={putLoading}
                    returnReceivedAt={returnReceivedAt}
                    setReturnReceivedAt={setReturnReceivedAt}
                />}
                handleClose={() => setReturnReceivedModal(false)}
                headerText="Return Received?"
            />}
            {approveYesOrNo && <YesOrNo
                zIndex={1040}
                handleYes={handleApproveYes}
                handleNo={() => setApproveYesOrNo(false)}
                headerText="Are you sure want to Approve?"
                yesBtnText="Yes"
                loading={putLoading}
            />}
            {returnApproveYesOrNo && <YesOrNo zIndex={1040}
                handleYes={handleReturnApproveYes}
                handleNo={() => setReturnApproveYesOrNo(false)}
                headerText="Are you sure want to Approve?"
                yesBtnText="Yes"
                loading={putLoading}
            />}
            {showDeclineModal && <Modal
                zIndex={1040}
                component={<Decline reason={reason}
                    setReason={setReason} />}
                handleClose={() => setShowDeclineModal(false)}
                headerText="Are you sure want to Decline?"
            />}
            {showReturnDeclineModal && <Modal
                zIndex={1040}
                component={<ReturnDecline
                    putLoading={putLoading}
                    reason={reason}
                    returnApproveOrDecline={returnApproveOrDecline}
                    setReason={setReason}
                />}
                handleClose={() => setShowReturnDeclineModal(false)}
                headerText="Are you sure want to Decline?"
            />}
        </div>
        <AdminHeader />
        {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> : <div className="container my-4">
            <div className="row">
                <div className="col-12 col-lg-6 mb-4">
                    <div className="border py-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <img className="fit-contain w-100 hover-scale" src={orderItem.mainImage && baseURL + '/' + orderItem.mainImage} alt="" style={{ maxHeight: '200px' }} />
                                </div>
                                <div className="col-12">
                                    <h6 className="my-2">{orderItem.productName}</h6>
                                    <h6 className="fw-bold">&#8377;{Math.ceil(orderItem.amount + orderItem.deliveryCharge)}</h6>
                                </div>
                                <div className="col-12 mb-3">
                                    <i className="fas fa-circle text-success blink me-2" style={{ fontSize: '.7rem' }} />
                                    <span className="h6 m-0 fw-bold text-capitalize">{orderItem.status}</span>
                                </div>
                                {(orderItem.status === RETURN_REQUEST || orderItem.status === CANCEL_REQUEST) && <><hr />
                                    <div className="col-12">
                                        <span className="small">{orderItem.userComment}</span>
                                    </div></>}
                                <div className="col-12 mt-3">
                                    {orderItem.status === CANCEL_REQUEST && <><button className="btn btn-success me-3" onClick={e => preventClick(e, handleApprove)}>Approve</button>
                                        <button className="btn btn-danger me-3" onClick={e => preventClick(e, handleDecline)}>Decline</button></>}

                                    {orderItem.status === RETURN_REQUEST && <><button className="btn btn-success me-3" onClick={e => preventClick(e, handleReturnApprove)}>Approve</button>
                                        <button className="btn btn-danger me-3" onClick={e => preventClick(e, handleReturnDecline)}>Decline</button></>}

                                    {orderItem.status === ORDERED && <button className="btn btn-success me-3" onClick={e => preventClick(e, handlePacked)}>Packed?</button>}

                                    {orderItem.status === PACKED && <button className="btn btn-success me-3" onClick={e => preventClick(e, handleShipped)}>Shipped?</button>}

                                    {orderItem.status === SHIPPED && <button className="btn btn-success me-3" onClick={e => preventClick(e, handleDelivered)}>Delivered?</button>}

                                    {orderItem.status === RETURN_APPROVED && <button className="btn btn-success me-3" onClick={e => preventClick(e, handleReturnShipped)}>Return Shipped?</button>}

                                    {orderItem.status === RETURN_SHIPPED && <button className="btn btn-success me-3" onClick={e => preventClick(e, handleReturnReceived)}>Return Received?</button>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                    <div className="border p-3">
                        <h6 className="text-dark fw-bold">Delivery Details</h6>
                        <span className="small text-dark fw-bold me-3">{orderItem.name}</span>
                        <span className=" border border-1 d-inline-block px-2 text-uppercase small  mb-2">{orderItem.addressType}</span>
                        <p className="small text-truncate">{orderItem.address}, {orderItem.locality}, {orderItem.city} - {orderItem.pin}, {orderItem.state}</p>
                        <p className="small text-dark fw-bold mb-1">Phone number</p>
                        <p className="small mb-1 text-truncate">
                            <i className="fas fa-fw fa-circle mx-2" style={{ fontSize: '.3rem' }} />
                            <span>{orderItem.phoneNumber}</span>
                        </p>
                        {orderItem.alternatePhoneNumber && <p className="small mb-1 text-truncate">
                            <i className="fas fa-fw fa-circle mx-2" style={{ fontSize: '.3rem' }} />
                            <span>{orderItem.alternatePhoneNumber}</span>
                        </p>}
                        <br />
                        {orderItem.emailId && <div className="w-50"><p className="small text-dark fw-bold mb-1">Email</p>
                            <p className="small mb-1 text-truncate">{orderItem.emailId}</p>
                        </div>}
                    </div>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                    <div className="border p-3">
                        <h6 className="text-dark fw-bold">Order Status</h6>
                        <h6 className="my-2 text-truncate fw-bold text-capitalize">{ORDERED}</h6>
                        <h6 className="mb-4 text-truncate small">{formateDate(orderItem.createTimestamp)}</h6>
                        {orderItem.packedAt && <><h6 className="my-2 text-truncate fw-bold text-capitalize">{PACKED}</h6>
                            <h6 className="mb-4 text-truncate small">{formateDate(orderItem.packedAt)}</h6></>}
                        {orderItem.shippedAt && <><h6 className="my-2 text-truncate fw-bold text-capitalize">{SHIPPED}</h6>
                            <h6 className="mb-4 text-truncate small">{formateDate(orderItem.shippedAt)}</h6></>}
                        {orderItem.cancelledAt && <><h6 className="my-2 text-truncate fw-bold text-capitalize">{CANCELLED}</h6>
                            <h6 className="mb-4 text-truncate small">{formateDate(orderItem.cancelledAt)}</h6></>}
                        {orderItem.deliveredAt && <><h6 className="my-2 text-truncate fw-bold text-capitalize">{DELIVERED}</h6>
                            <h6 className="mb-4 text-truncate small">{formateDate(orderItem.deliveredAt)}</h6></>}
                        {orderItem.returnApprovedAt && <><h6 className="my-2 text-truncate fw-bold text-capitalize">{RETURN_APPROVED}</h6>
                            <h6 className="mb-4 text-truncate small">{formateDate(orderItem.returnApprovedAt)}</h6></>}
                        {orderItem.returnShippedAt && <><h6 className="my-2 text-truncate fw-bold text-capitalize">{RETURN_SHIPPED}</h6>
                            <h6 className="mb-4 text-truncate small">{formateDate(orderItem.returnShippedAt)}</h6></>}
                        {orderItem.returnReceivedAt && <><h6 className="my-2 text-truncate fw-bold text-capitalize">{RETURN_RECEIVED}</h6>
                            <h6 className="mb-4 text-truncate small">{formateDate(orderItem.returnReceivedAt)}</h6></>}
                    </div>
                </div>
            </div>
        </div>}
    </div>
}

const Decline = ({ approveOrDecline, reason, putLoading, setReason }) => {
    return <div className="w-100">
        <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
        <textarea name="reason" id="reason" cols="30" rows="5" className="form-control mb-3" placeholder="Reason" style={{ resize: 'none' }} onChange={e => setReason(e.target.value)} value={reason} />
        {(putLoading || String(reason).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Decline</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, approveOrDecline)}>Decline</button>}
    </div>
}

const Shipped = ({ changeStatus, putLoading, shippingInfo, setShippingInfo }) => {
    return <div className="w-100">
        <label htmlFor="shippingInfo">Shipping Info <span className="text-danger">&#42;</span></label>
        <textarea name="shippingInfo" id="shippingInfo" cols="30" rows="5" className="form-control mb-3" placeholder="Shipping Info" style={{ resize: 'none' }} onChange={e => setShippingInfo(e.target.value)} value={shippingInfo} />
        {(putLoading || String(shippingInfo).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Continue</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, changeStatus)}>Continue</button>}
    </div>
}

const Delivered = ({ deliveredAt, setDeliveredAt, putLoading, changeStatus }) => {
    return <div className="w-100">
        <label htmlFor="deliveredAt">Delivered At <span className="text-danger">&#42;</span></label>
        <input type="datetime-local" name="deliveredAt" id="deliveredAt" className="form-control form-control-lg my-2" onChange={e => setDeliveredAt(e.target.value)} value={deliveredAt} />
        {(putLoading || String(deliveredAt).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Continue</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, changeStatus)}>Continue</button>}
    </div>
}

const ReturnShipped = ({ setReturnShippedAt, returnShippedAt, putLoading, changeStatus }) => {
    return <div className="w-100">
        <label htmlFor="returnShippedAt">Return Shipped At <span className="text-danger">&#42;</span></label>
        <input type="datetime-local" name="returnShippedAt" id="returnShippedAt" className="form-control form-control-lg my-2" onChange={e => setReturnShippedAt(e.target.value)} value={returnShippedAt} />
        {(putLoading || String(returnShippedAt).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Continue</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, changeStatus)}>Continue</button>}
    </div>
}

const ReturnReceived = ({ changeStatus, setReturnReceivedAt, returnReceivedAt, putLoading }) => {
    return <div className="w-100">
        <label htmlFor="returnReceivedAt">Return Received At <span className="text-danger">&#42;</span></label>
        <input type="datetime-local" name="returnReceivedAt" id="returnReceivedAt" className="form-control form-control-lg my-2" onChange={e => setReturnReceivedAt(e.target.value)} value={returnReceivedAt} />
        {(putLoading || String(returnReceivedAt).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Continue</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, changeStatus)}>Continue</button>}
    </div>
}

const ReturnDecline = ({ returnApproveOrDecline, setReason, reason, putLoading }) => {
    return <div className="w-100">
        <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
        <textarea name="reason" id="reason" cols="30" rows="5" className="form-control mb-3" placeholder="Reason" style={{ resize: 'none' }} onChange={e => setReason(e.target.value)} value={reason} />
        {(putLoading || String(reason).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Decline</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, returnApproveOrDecline)}>Decline</button>}
    </div>
}

export default AdminOrderDetails