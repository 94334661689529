import React from "react"
import CustomerBody from "./Body"
import CustomerCategoryBar from "./CategoryBar"
import CustomerFooter from "./Footer"
import CustomerHeader from "./Header"
import CustomerSlide from "./Slide"

const CustomerHome = () => {
    return <div className="fixed-top-bar">
        <CustomerHeader enableButtons enableSearch />
        <CustomerCategoryBar />
        <CustomerSlide />
        <CustomerBody />
        <CustomerFooter />
    </div>
}

export default CustomerHome