import React from "react"
import { Link } from "react-router-dom"

const CustomerFooter = () => {
    return <footer className="bg-black">
        <div className="container p-3">
            <div className="row">
                <div className="col-12 col-sm-6 col-lg-3 mb-3">
                    <div className="text-muted small fw-bold text-uppercase">About</div>
                    <div><Link to="#" className="text-white small hover-underline">Contact Us</Link></div>
                    <div><Link to="#" className="text-white small hover-underline">About Us</Link></div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-3">
                    <div className="text-muted small fw-bold text-uppercase">Help</div>
                    <div><Link to="#" className="text-white small hover-underline">Payments</Link></div>
                    <div><Link to="#" className="text-white small hover-underline">Shipping</Link></div>
                    <div><Link to="#" className="text-white small hover-underline">Cancellation &amp; Returns</Link></div>
                    <div><Link to="#" className="text-white small hover-underline">FAQ</Link></div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-3">
                    <div className="text-muted small fw-bold text-uppercase">Social</div>
                    <div><Link to="#" className="text-white small hover-underline">Facebook</Link></div>
                    <div><Link to="#" className="text-white small hover-underline">Twitter</Link></div>
                    <div><Link to="#" className="text-white small hover-underline">YouTube</Link></div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-3">
                    <div className="text-muted small fw-bold text-uppercase">Registered Address</div>
                    <div className="d-block text-white small">
                        <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae non dignissimos fuga ipsam debitis, rerum aliquam dolore reprehenderit doloribus nisi!</span>
                        <br />
                        <br />
                        <span>Phone Number: +91 12345 67890</span>
                        <br />
                        <span>E-mail: mail@myshop.com</span>
                    </div>
                </div>
            </div>
            <hr />
            <div className="w-100 text-center text-white">&copy; 2021 myshop.com</div>
        </div>
    </footer>
}

export default CustomerFooter