import React from "react"
import { useParams } from "react-router-dom"
import { EDIT } from "../../include/constant"
import SlideForm from "../common/SlideForm"
import SuperAdminHeader from "./Header"

const SuperAdminEditSlide = () => {
    const { id } = useParams()

    return <div className="fixed-top-bar">
        <SuperAdminHeader />
        <SlideForm
            id={id}
            title="Edit Slide"
            type={EDIT}
        />
    </div>
}

export default SuperAdminEditSlide