import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { api, customerReqConfig } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { discountValue, emptyCart, getCheckout, getCustomer, isEmptyObj, preventArrow, preventClick, preventWheel } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"

const CustomerCheckout = () => {
    const user = getCustomer()
    const history = useHistory()
    const toast = useNotification()

    const [fetchLoading, setFetchLoading] = useState(true)
    const [verifyCodeLoading, setVerifyCodeLoading] = useState(false)
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false)
    const [orderPlaced, setOrderPlaced] = useState(false)
    const [voucherVerified, setVoucherVerified] = useState(false)

    const [placeOrderData, setPlaceOrderData] = useState({})
    const [voucherData, setVoucherData] = useState({})
    const [addressList, setAddressList] = useState([])
    const [selectedAddress, setSelectedAddress] = useState(0)
    const [save, setSave] = useState(0)

    const [voucher, setVoucher] = useState('')
    const [selectedAddressError, setSelectedAddressError] = useState('')
    const [selectedPayment, setSelectedPayment] = useState('')
    const [selectedPaymentError, setSelectedPaymentError] = useState('')
    const [voucherError, setVoucherError] = useState('')
    const [voucherDiscountAmount, setVoucherDiscountAmount] = useState('')

    useEffect(() => {
        const checkout = getCheckout()
        if (!isEmptyObj(checkout)) setPlaceOrderData(checkout)
        else history.push('/')
    }, [history])

    useEffect(() => getAddress(user.id), [user.id])

    useEffect(() => {
        if (addressList.length > 0) setSelectedAddress(addressList[0].id)
    }, [addressList])

    useEffect(() => {
        if (voucherVerified && !isEmptyObj(voucherData)) {
            const voucherDiscountAmount = discountValue((Number(placeOrderData.billAmount) - Number(placeOrderData.totalDiscount)), voucherData.value, voucherData.mode)
            setVoucherDiscountAmount(voucherDiscountAmount)
        }
    }, [placeOrderData.billAmount, placeOrderData.totalDiscount, voucherData, voucherVerified])

    useEffect(() => {
        if (voucherVerified) {
            setPlaceOrderData(prev => ({
                ...prev,
                voucherCode: voucherData.code,
                voucherText: voucher + ' - ' + voucherData.text,
                voucherMode: voucherData.mode,
                voucherValue: voucherData.value,
                voucherDiscountAmount: voucherDiscountAmount,
                totalAmount: (prev.billAmount + prev.totalDeliveryCharge) - (prev.totalDiscount + voucherDiscountAmount),
            }))
        }
    }, [voucherData.code, voucherData.mode, voucherData.text, voucherData.value, voucherDiscountAmount, voucherVerified, voucher])

    useEffect(() => {
        setSave(Number(voucherDiscountAmount) + Number(placeOrderData.totalDiscount))
    }, [placeOrderData.totalDiscount, voucherDiscountAmount])

    useEffect(() => {
        if (selectedPayment !== '') {
            placeOrderData.orderItems.forEach(item => {
                item.paymentMode = selectedPayment
                item.paymentStatus = 'Pending'
                item.paymentInfo = selectedPayment
            })
        }
    }, [placeOrderData.orderItems, selectedPayment])

    useEffect(() => {
        if (orderPlaced) {
            history.push('/profile/orderPlaced')
        }
    }, [orderPlaced, history])

    const getAddress = (id) => {
        setFetchLoading(true)
        api.get('/customer_address/?customerId=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setAddressList(response.data.data)
            }
        }).catch(error => {
            setAddressList([])
        }).finally(() => setFetchLoading(false))
    }

    const getVoucherCodeDetails = (afterGet) => {
        api.get('/voucher/?code=' + voucher, customerReqConfig()).then(response => {
            if (response.status === 200) {
                if (afterGet) afterGet(response.data.data[0])
            }
        }).catch(error => { })
    }

    const handleSelectAddress = (value) => {
        setSelectedAddressError('')
        setSelectedAddress(value)
    }

    const handleSelectPayment = (value) => {
        setSelectedPaymentError('')
        setSelectedPayment(value)
    }

    const handleVoucher = (value) => {
        setVoucherError('')
        setVoucher(String(value).toUpperCase())
    }

    const handleVerifyVoucherCode = () => {
        let error = false
        const data = {
            userId: user.id,
            code: String(voucher).trim()
        }

        if (data.code === '') {
            setVoucherError('Voucher code required')
            error = true
        } else if (data.code.length !== 6) {
            setVoucherError('Voucher code must be 6 characters long')
            error = true
        }

        if (!error) {
            setVerifyCodeLoading(true)
            api.post('/voucher/?flag=verifyvoucher', data, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    getVoucherCodeDetails(voucherDetails => {
                        setVoucherData(voucherDetails)
                        setVoucherVerified(true)
                        toast({ type: SUCCESS, message: response.data.message })
                    })
                }
            }).catch(error => {
                error.response ?
                    setVoucherError(error.response.data.message) :
                    setVoucherError(error.message)
            }).finally(() => setVerifyCodeLoading(false))
        }
    }

    const handleContinue = () => {
        let error = false
        if (selectedAddress <= 0 || selectedAddress === '') {
            setSelectedAddressError('Select an address')
            error = true
        }

        if (selectedPayment === '') {
            setSelectedPaymentError('Select an payment method')
            error = true
        }

        if (!error) {
            const addressIndex = addressList.findIndex(al => String(al.id) === String(selectedAddress))
            if (addressIndex >= 0) {
                const address = addressList[addressIndex]

                placeOrderData.customerId = user.id
                placeOrderData.name = address.name
                placeOrderData.phoneNumber = address.phoneNumber
                placeOrderData.alternatePhoneNumber = address.alternatePhoneNumber
                placeOrderData.locality = address.locality
                placeOrderData.address = address.address
                placeOrderData.city = address.city
                placeOrderData.state = address.state
                placeOrderData.landmark = address.landmark
                placeOrderData.pin = address.pin
                placeOrderData.addressType = address.addressType

                setPlaceOrderLoading(true)
                api.post('/orders/', placeOrderData, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        emptyCart()
                        toast({ type: SUCCESS, message: response.data.message })
                        setOrderPlaced(true)
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally(() => setPlaceOrderLoading(false))
            }
        }
    }

    return <div className="fixed-top-bar">
        <CustomerHeader />
        <div className="container my-3">
            <div className="row">
                <div className="col-12 col-xl-6 mb-3">
                    <div className={`border ${selectedAddressError === '' ? 'border-1 shadow-sm' : 'border-danger'}`}>
                        <div className={`${selectedAddressError === '' ? ' border-bottom' : 'bg-danger'} px-3 py-2`}>
                            <h6 className={`${selectedAddressError === '' ? 'text-muted' : 'text-white'} m-0 text-uppercase`}>DELIVERY ADDRESS</h6>
                        </div>
                        <div className="p-3">
                            {fetchLoading ? <div className="p-5"><Spinner color="danger" /></div> :
                                addressList && addressList.length > 0 ? <>
                                    {addressList.map((address, index) => {
                                        return <div key={nanoid()}>
                                            <Address
                                                address={address}
                                                handleSelectAddress={handleSelectAddress}
                                                selectedAddress={selectedAddress}
                                                key={nanoid()}
                                            />
                                            {addressList.length - 1 !== index && <hr />}
                                        </div>
                                    })}
                                    <span className="text-danger my-2 small fw-bold">{selectedAddressError}</span>
                                    <br />
                                </> : <Error mainText="No Address found" secondaryText={<Link to="/profile#address">Add new address</Link>} />}
                        </div>
                    </div>
                    <div className={`border mt-3 ${selectedPaymentError === '' ? 'border-1 shadow-sm' : 'border-danger'}`}>
                        <div className={`${selectedPaymentError === '' ? ' border-bottom' : 'bg-danger'} px-3 py-2`}>
                            <h6 className={`${selectedPaymentError === '' ? 'text-muted' : 'text-white'} m-0 text-uppercase`}>Payment option</h6>
                        </div>
                        <div className="p-3">
                            <div className="form-check d-flex justify-content-start align-items-center p-0">
                                <input className="form-check-input m-0" type="radio" name="payment" checked={selectedPayment === 'Cash on Delivery'} onChange={e => handleSelectPayment('Cash on Delivery')} id="cashOnDelivery" />
                                <label className="form-check-label form-check-label  fw-bold ms-3" htmlFor="cashOnDelivery">Cash on Delivery</label>
                            </div>
                            {/* <hr />
                            <div className="form-check d-flex justify-content-start align-items-center p-0">
                                <input className="form-check-input m-0" type="radio" name="payment" />
                                <label className="form-check-label form-check-label  fw-bold ms-3">Credit / Debit / ATM Card</label>
                            </div>
                            <div className="row">
                                <div className="col my-3">
                                    <input type="number" className="form-control form-control-lg" placeholder="Enter Card Number" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} />
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-2">
                                    <div className="d-flex align-items-center">
                                        <select className="form-select shadow-none valid-thru me-3">
                                            <option value="">MM</option>
                                            {[...Array(12)].map((_, index) => {
                                                return <option key={nanoid()} value={index + 1}>{index + 1}</option>
                                            })}
                                        </select>
                                        <select className="form-select shadow-none valid-thru me-3">
                                            <option value="">YY</option>
                                            {[...Array(40)].map((_, index) => {
                                                let date = new Date()
                                                let year = date.getFullYear() + index
                                                year = year.toString().substr(-2)
                                                return <option key={nanoid()} value={year}>{year}</option>
                                            })}
                                        </select>
                                        <input type="password" className="form-control valid-thru me-3" placeholder="CVV" />
                                    </div>
                                </div>
                            </div>*/}
                            <span className="text-danger my-2 small fw-bold">{selectedPaymentError}</span>
                            <br />
                        </div>
                    </div>
                    {/* {voucherVerified ?
                        <div className="p-3 text-white bg-success text-uppercase fw-bold mt-3">Voucher code added - {voucher}</div> :
                        <div className="border border-1 mt-3 shadow-sm">
                            <div className=" px-3 py-2 border-bottom">
                                <h6 className="text-muted m-0">ADD GIFT CARD</h6>
                            </div>
                            <div className="p-3">
                                <div className="row">
                                    <div className="col">
                                        <input type="text" className={`form-control form-control-lg mb-2 ${voucherError !== '' ? 'border border-danger' : ''}`} placeholder="Voucher Number" onChange={e => handleVoucher(e.target.value)} value={voucher} disabled={voucherVerified} readOnly={voucherVerified} />
                                    </div>
                                    <div className="col">
                                        {verifyCodeLoading ?
                                            <button className="btn btn-lg btn-primary text-uppercase small fw-bold d-flex align-items-center justify-content-end">
                                                <span className="me-3">Verify</span>
                                                <Spinner spinnerSize="sm" color="white" />
                                            </button> :
                                            <button className="btn btn-lg btn-primary" onClick={e => preventClick(e, handleVerifyVoucherCode)} disabled={voucherVerified}>Verify</button>}
                                    </div>
                                </div>
                                <span className="text-danger my-2 small fw-bold">{voucherError}</span>
                                <br />
                            </div>
                        </div>} */}
                </div>
                <div className="col-12 col-xl-6">
                    <div className="border border-1 shadow-sm">
                        <div className=" px-3 py-2 border-bottom">
                            <h6 className="text-muted m-0">PRICE DETAILS</h6>
                        </div>
                        <div className="p-3">
                            <h6 className="d-flex justify-content-between">
                                <span className="text-muted">Price ({placeOrderData.totalItems} Items)</span>
                                <span>&#8377;{placeOrderData.billAmount}</span>
                            </h6>
                            {voucherVerified && <h6 className="d-flex justify-content-between text-success">
                                <span>{voucher} - {voucherData.text}</span>
                                <span>&#8377;{placeOrderData.voucherDiscountAmount}</span>
                            </h6>}
                            <h6 className="d-flex justify-content-between text-success">
                                <span>Discount</span>
                                <span>&#8377;{placeOrderData.totalDiscount}</span>
                            </h6>
                            <h6 className="d-flex justify-content-between">
                                <span className="text-muted">Delivery Charge</span>
                                {(placeOrderData.totalDeliveryCharge === '' || placeOrderData.totalDeliveryCharge === 0 || placeOrderData.totalDeliveryCharge === '0') ?
                                    <span className="text-success text-uppercase">Free</span> :
                                    <span>&#8377;{placeOrderData.totalDeliveryCharge}</span>}
                            </h6>
                            <hr />
                            <h6 className="d-flex justify-content-between  fw-bold">
                                <span>Total Amount</span>
                                <span>&#8377;{placeOrderData.totalAmount}</span>
                            </h6>
                            <hr />
                            <span className="text-success small">You will save ₹{save} on this order</span>
                            <div className="d-flex justify-content-end align-items-center">
                                {placeOrderLoading ?
                                    <button className="btn btn-lg" onClick={e => e.preventDefault()}>Continue</button> :
                                    <button className="btn btn-lg btn-primary" onClick={e => preventClick(e, handleContinue)}>Continue</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const Address = ({ address, handleSelectAddress, selectedAddress }) => {
    return <div className="row">
        <div className="col-1">
            <input className="form-check-input m-0" type="radio" name="address" checked={String(selectedAddress) === String(address.id)} onChange={e => handleSelectAddress(String(address.id))} />
        </div>
        <div className="col-10">
            <span className="border border-1 d-inline-block px-2 text-uppercase small  mb-2">{address.addressType}</span>
            <h6 className="d-flex align-items-center">
                <span>{address.name}</span>
                <i className="fa fa-circle mx-2" style={{ fontSize: '.3rem' }} />
                <span>{address.phoneNumber}</span>
                {address.alternatePhoneNumber && <>
                    <i className="fa fa-circle mx-2" style={{ fontSize: '.3rem' }} />
                    <span>{address.alternatePhoneNumber}</span>
                </>}
            </h6>
            <p className="m-0 mt-1">{address.address}, {address.locality}, {address.city}, {address.state} - {address.pin}</p>
            <Link className="text-primary mt-3 me-3 d-inline-block" to="/profile#address">Edit</Link>
        </div>
    </div>
}

export default CustomerCheckout