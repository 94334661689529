import React, { useEffect, useState } from "react"
import { nanoid } from "nanoid"
import { api, baseURL, customerReqConfig } from "../../include/api"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const CustomerSlide = () => {
    const [fetchLoading, setFetchLoading] = useState(false)
    const [slideList, setSlideList] = useState([0])

    const getSlides = () => {
        setFetchLoading(true)
        api.get('/slide_show/', customerReqConfig).then(response => {
            if (response.status === 200) {
                setSlideList(response.data.data)
            }
        }).catch(error => { }).finally(() => {
            setFetchLoading(false)
        })
    }

    useEffect(() => {
        getSlides()
    }, [])

    return <div className="bg-white">
        {fetchLoading ? <div className="w-100 bg-light" style={{ height: '300px' }} /> : <Carousel
            autoPlay
            infiniteLoop
            interval={3000}
            preventMovementUntilSwipeScrollTolerance
            showThumbs={false}
            transitionTime={100}
            showStatus={false}
        >
            {slideList.map((slide, index) => {
                return <CarouselItem slide={slide} key={nanoid()} />
            })}
        </Carousel>}
    </div>
}

const CarouselItem = ({ slide }) => {
    return <div className="w-100" style={{ height: '300px' }}>
        <img className="fit-cover w-100 h-100" src={slide.imageUrl && baseURL + '/' + slide.imageUrl} alt="" />
    </div>
}

export default CustomerSlide