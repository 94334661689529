import React, { useState } from 'react'
import './styles/App.css'
import './styles/LargeScreen.css'
import './styles/MediumScreen.css'
import './styles/SmallScreen.css'
import './styles/SideNavBar.css'
import { getAdmin, getCustomer, getSuperAdmin, isEmptyObj, setToLocalStorage } from './include/function'
import { ADMIN, ADMIN_TOKEN, CUSTOMER, CUSTOMER_TOKEN, SUPER_ADMIN, SUPER_ADMIN_TOKEN } from './include/constant'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import CustomerHome from './components/customer/Home'
import AdminLogin from './components/admin/Login'
import AdminLogout from './components/admin/Logout'
import SuperAdminLogin from './components/super/Login'
import SuperAdminLogout from './components/super/Logout'
import CustomerProduct from './components/customer/Product'
import PageNotFound from './components/add-ons/404'
import CustomerCategory from './components/customer/Category'
import CustomerCart from './components/customer/Cart'
import CustomerLogin from './components/customer/Login'
import CustomerSignup from './components/customer/Signup'
import CustomerLogout from './components/customer/Logout'
import CustomerCheckout from './components/customer/Checkout'
import CustomerProfile from './components/customer/Profile'
import SuperAdminCategory from './components/super/Category'
import SuperAdminCustomer from './components/super/Customer'
import SuperAdminShop from './components/super/Shop'
import SuperAdminAddCategory from './components/super/AddCategory'
import SuperAdminEditCategory from './components/super/EditCategory'
import SuperAdminAddShop from './components/super/AddShop'
import SuperAdminEditShop from './components/super/EditShop'
import SuperAdminVoucher from './components/super/Voucher'
import SuperAdminAddVoucher from './components/super/AddVoucher'
import SuperAdminEditVoucher from './components/super/EditVoucher'
import SuperAdminSlide from './components/super/Slide'
import SuperAdminAddSlide from './components/super/AddSlide'
import SuperAdminEditSlide from './components/super/EditSlide'
import AdminOrders from './components/admin/Orders'
import AdminProduct from './components/admin/Product'
import AdminProfile from './components/admin/Profile'
import AdminAddProduct from './components/admin/AddProduct'
import AdminEditProduct from './components/admin/EditProduct'
import CustomerOrderDetails from './components/customer/OrderDetails'
import AdminOrderDetails from './components/admin/OrderDetails'
import SuperAdminHomePage from './components/super/HomePage'

const App = () => {
    const [customer, setCustomer] = useState(getCustomer())
    const [admin, setAdmin] = useState(getAdmin())
    const [superAdmin, setSuperAdmin] = useState(getSuperAdmin())

    const handleCustomer = (customer, token) => {
        setToLocalStorage(CUSTOMER, customer)
        setToLocalStorage(CUSTOMER_TOKEN, token)
        setTimeout(() => {
            setCustomer(customer)
        }, 500)
    }

    const handleAdmin = (admin, token) => {
        setToLocalStorage(ADMIN, admin)
        setToLocalStorage(ADMIN_TOKEN, token)
        setTimeout(() => {
            setAdmin(admin)
        }, 500)
    }

    const handleSuperAdmin = (superAdmin, token) => {
        setToLocalStorage(SUPER_ADMIN, superAdmin)
        setToLocalStorage(SUPER_ADMIN_TOKEN, token)
        setTimeout(() => {
            setSuperAdmin(superAdmin)
        }, 500)
    }

    return <BrowserRouter>
        <Switch>
            <Route exact path='/' component={CustomerHome} />

            <Route path="/admin/login">
                {isEmptyObj(admin) ? <AdminLogin handleAdmin={handleAdmin} /> : <Redirect to="/admin" />}
            </Route>
            <Route path="/admin/logout">
                {isEmptyObj(admin) ? <Redirect to="/admin" /> : <AdminLogout setAdmin={setAdmin} />}
            </Route>
            <Route path="/admin/order">
                <AuthRoute user={admin} component={AdminOrders} redirect="/admin/login" />
            </Route>
            <Route path="/admin/order-details/:id">
                <AuthRoute user={admin} component={AdminOrderDetails} redirect="/admin/login" />
            </Route>
            <Route path="/admin/product">
                <AuthRoute user={admin} component={AdminProduct} redirect="/admin/login" />
            </Route>
            <Route path="/admin/add-product">
                <AuthRoute user={admin} component={AdminAddProduct} redirect="/admin/login" />
            </Route>
            <Route path="/admin/edit-product/:id">
                <AuthRoute user={admin} component={AdminEditProduct} redirect="/admin/login" />
            </Route>
            <Route path="/admin/profile">
                <AuthRoute user={admin} component={AdminProfile} redirect="/admin/login" />
            </Route>
            <Route path="/admin">
                <Redirect to="/admin/order" />
            </Route>


            <Route path="/super/login">
                {isEmptyObj(superAdmin) ? <SuperAdminLogin handleSuperAdmin={handleSuperAdmin} /> : <Redirect to="/super" />}
            </Route>
            <Route path="/super/logout">
                {isEmptyObj(superAdmin) ? <Redirect to="/super" /> : <SuperAdminLogout setSuperAdmin={setSuperAdmin} />}
            </Route>
            <Route path="/super/category">
                <AuthRoute user={superAdmin} component={SuperAdminCategory} redirect="/super/login" />
            </Route>
            <Route path="/super/add-category">
                <AuthRoute user={superAdmin} component={SuperAdminAddCategory} redirect="/super/login" />
            </Route>
            <Route path="/super/edit-category/:id">
                <AuthRoute user={superAdmin} component={SuperAdminEditCategory} redirect="/super/login" />
            </Route>
            <Route path="/super/customer">
                <AuthRoute user={superAdmin} component={SuperAdminCustomer} redirect="/super/login" />
            </Route>
            <Route path="/super/shop">
                <AuthRoute user={superAdmin} component={SuperAdminShop} redirect="/super/login" />
            </Route>
            <Route path="/super/add-shop">
                <AuthRoute user={superAdmin} component={SuperAdminAddShop} redirect="/super/login" />
            </Route>
            <Route path="/super/edit-shop/:id">
                <AuthRoute user={superAdmin} component={SuperAdminEditShop} redirect="/super/login" />
            </Route>
            <Route path="/super/voucher">
                <AuthRoute user={superAdmin} component={SuperAdminVoucher} redirect="/super/login" />
            </Route>
            <Route path="/super/add-voucher">
                <AuthRoute user={superAdmin} component={SuperAdminAddVoucher} redirect="/super/login" />
            </Route>
            <Route path="/super/edit-voucher/:id">
                <AuthRoute user={superAdmin} component={SuperAdminEditVoucher} redirect="/super/login" />
            </Route>
            <Route path="/super/slide">
                <AuthRoute user={superAdmin} component={SuperAdminSlide} redirect="/super/login" />
            </Route>
            <Route path="/super/add-slide">
                <AuthRoute user={superAdmin} component={SuperAdminAddSlide} redirect="/super/login" />
            </Route>
            <Route path="/super/edit-slide/:id">
                <AuthRoute user={superAdmin} component={SuperAdminEditSlide} redirect="/super/login" />
            </Route>
            <Route path="/super/home_page">
                <AuthRoute user={superAdmin} component={SuperAdminHomePage} redirect="/super/login" />
            </Route>
            <Route path="/super">
                <Redirect to="/super/shop" />
            </Route>


            <Route path='/product/:id' component={CustomerProduct} />
            <Route path='/product' component={PageNotFound} />
            <Route path='/category/:id' component={CustomerCategory} />
            <Route path='/category' component={PageNotFound} />
            <Route path='/cart' component={CustomerCart} />

            <Route path="/login">
                {isEmptyObj(customer) ? <CustomerLogin handleCustomer={handleCustomer} /> : <Redirect to="/" />}
            </Route>
            <Route path="/register">
                {isEmptyObj(customer) ? <CustomerSignup handleCustomer={handleCustomer} /> : <Redirect to="/" />}
            </Route>
            <Route path="/logout">
                {isEmptyObj(customer) ? <Redirect to="/" /> : <CustomerLogout setCustomer={setCustomer} />}
            </Route>
            <Route path="/checkout">
                <AuthRoute user={customer} component={CustomerCheckout} redirect="/login" />
            </Route>
            <Route path="/profile/personal-info">
                <AuthRoute user={customer} component={CustomerProfile} redirect="/login" />
            </Route>
            <Route path="/order-details/:id">
                <AuthRoute user={customer} component={CustomerOrderDetails} redirect="/login" />
            </Route>
            <Route path="/profile/orders">
                <AuthRoute user={customer} component={CustomerProfile} redirect="/login" />
            </Route>
            <Route path="/profile/address">
                <AuthRoute user={customer} component={CustomerProfile} redirect="/login" />
            </Route>
            <Route path="/profile/change-password">
                <AuthRoute user={customer} component={CustomerProfile} redirect="/login" />
            </Route>
            <Route path="/profile">
                <Redirect to="/profile/personal-info" />
            </Route>

            <Route component={CustomerHome} />
        </Switch>
    </BrowserRouter>
}

const AuthRoute = ({ user, redirect, component, props }) => {
    const Component = component
    return isEmptyObj(user) ? <Redirect to={redirect} /> : <Component {...props} />
}

export default App
