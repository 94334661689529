import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { api, customerReqConfig } from "../../include/api"
import { CUSTOMER, ERROR, SUCCESS } from "../../include/constant"
import { getCustomer, preventClick, setToLocalStorage, validateEmail, validatePhoneNumber } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"

const CustomerPersonalInfo = () => {
    const toast = useNotification()
    const history = useHistory()
    const user = getCustomer()

    const [name, setName] = useState(user.name)
    const [emailId, setEmailId] = useState(user.emailId)
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)

    const [nameError, setNameError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [emailIdError, setEmailIdError] = useState('')

    const [loading, setLoading] = useState(false)
    const [edit, setEdit] = useState(false)

    const handleName = (value) => {
        setNameError('')
        setName(value)
    }

    const handleEmailId = (value) => {
        setEmailIdError('')
        setEmailId(value)
    }

    const handlePhoneNumber = (value) => {
        setPhoneNumberError('')
        setPhoneNumber(value)
    }

    const setDefault = () => {
        setName(user.name)
        setEmailId(user.emailId)
        setPhoneNumber(user.phoneNumber)
    }

    const handleEdit = () => {
        let error = false
        const data = {
            id: String(user.id).trim(),
            name: String(name).trim(),
            emailId: String(emailId).trim().toLowerCase(),
            phoneNumber: String(phoneNumber).trim(),
        }

        if (data.name === '') {
            setNameError('Name required')
            error = true
        }

        if (data.phoneNumber === '') {
            setPhoneNumberError('Phone number required')
            error = true
        }

        if (data.emailId !== '' && !validateEmail(data.emailId)) {
            setEmailIdError('Invalid email address')
            error = true
        }

        if (data.name.length > 100) {
            setNameError('Name is too long')
            error = true
        }

        if (!validatePhoneNumber(data.phoneNumber)) {
            setPhoneNumberError('Invalid phone number')
            error = true
        }

        if (!error) {
            setLoading(true)
            api.put('/customer/?id=' + user.id, data, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    setToLocalStorage(CUSTOMER, data)
                    toast({ type: SUCCESS, message: response.data.message })
                    setEdit(false)
                    setTimeout(() => {
                        history.push('/profile')
                    }, 500)
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }
            ).finally(() => setLoading(false))
        }
    }

    const toggleEdit = () => {
        setEdit(!edit)
        setNameError('')
        setPhoneNumberError('')
        setEmailIdError('')
        setDefault()
    }

    return <div className="container my-3">
        <h5 className="mb-3">Personal Information</h5>
        <h6 className="text-truncate">
            <span>Name</span>
            <Link className="text-primary ms-3" to="#" onClick={e => preventClick(e, toggleEdit)}>{edit ? 'Cancel' : 'Edit'}</Link>
            {edit && !loading && <Link className="text-primary ms-3" to="#" onClick={e => preventClick(e, handleEdit)}>Save</Link>}
        </h6>
        <div className="mt-3">
            <div className="row">
                <div className="col-12 col-lg-8 col-xl-6">
                    <input type="text" className={`form-control form-control-lg me-3 ${!edit ? 'cursor-no-drop' : ''} ${nameError !== '' && 'border-danger'}`} disabled={!edit} value={name} onChange={e => handleName(e.target.value)} placeholder="Name" />
                    <span className="text-danger my-2 small fw-bold">{nameError}</span>
                    <br />
                </div>
            </div>
        </div>
        <h6 className="mt-3 text-truncate">Email Address</h6>
        <div className="mt-3">
            <div className="row">
                <div className="col-12 col-lg-8 col-xl-6">
                    <input type="email" className={`form-control form-control-lg me-3 ${!edit ? 'cursor-no-drop' : ''} ${emailIdError !== '' && 'border-danger'}`} disabled={!edit} value={emailId} onChange={e => handleEmailId(e.target.value)} placeholder="Email Address" />
                    <span className="text-danger my-2 small fw-bold">{emailIdError}</span>
                    <br />
                </div>
            </div>
        </div>
        <h6 className="mt-3 text-truncate">Mobile Number</h6>
        <div className="mt-3">
            <div className="row">
                <div className="col-12 col-lg-8 col-xl-6">
                    <input type="number" className={`form-control form-control-lg me-3 ${!edit ? 'cursor-no-drop' : ''} ${phoneNumberError !== '' && 'border-danger'}`} disabled={!edit} value={phoneNumber} onChange={e => handlePhoneNumber(e.target.value)} placeholder="Mobile Number" />
                    <span className="text-danger my-2 small fw-bold">{phoneNumberError}</span>
                    <br />
                </div>
            </div>
        </div>
    </div>
}

export default CustomerPersonalInfo