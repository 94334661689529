import React from "react"
import { ADD } from "../../include/constant"
import ShopForm from "../common/ShopForm"
import SuperAdminHeader from "./Header"

const SuperAdminAddShop = () => {
    return <div className="fixed-top-bar">
        <SuperAdminHeader />
        <ShopForm
            title="Add Shop"
            type={ADD}
        />
    </div>
}

export default SuperAdminAddShop