import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { getCustomer } from "../../include/function"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import { nanoid } from "nanoid"

const CustomerOrders = () => {
    const user = getCustomer()

    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])

    const getOrders = useCallback(() => {
        setLoading(true)
        api.get('/orders/?customerId=' + user.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setOrders(response.data.data)
            }
        }).catch(error => {
            setOrders([])
        }).finally(() => setLoading(false))
    }, [user.id])

    useEffect(() => {
        getOrders()
    }, [getOrders])

    return <div className="container my-3">
        <h5 className="mb-3">Orders</h5>
        {loading ? <div className="py-5"><Spinner color="danger" /></div> :
            <div className="row">
                {orders.length > 0 ?
                    orders.map(order => order.orderItems.map(item => {
                        return <Order key={nanoid()} item={item} />
                    })) : <Error mainText="No orders found!" />}
            </div>}
    </div>
}

const Order = ({ item }) => {
    return <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4">
        <Link to={"/order-details/" + item.id} className="text-center d-block p-3 border my-2 text-dark">
            <img className="fit-contain h-100 hover-scale" style={{ minWidth: '50px', maxWidth: '100px' }} src={item.mainImage && baseURL + '/' + item.mainImage} alt="" />
            <h6 className="my-2 text-truncate small">{item.productName}</h6>
            <span className="small me-3">&#8377;{Math.ceil(item.amount + item.deliveryCharge)}</span>
            <i className="fas fa-circle fa-fw text-success blink me-1" style={{ fontSize: '.7rem' }} />
            <span className="text-capitalize">{item.status}</span>
        </Link>
    </div>
}

export default CustomerOrders